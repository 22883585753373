import { Paper } from '../components/Paper/Paper';
import {
  Grid,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import styled from 'styled-components';
import { FormNavigation } from '../components/FormNavigation';
import { useAppSelector } from '../app/store';
import { selectPensionForm } from '../app/applications/applicationsSlice';

const dummyPersonalInfo = [
  {
    value: 'Björgvin Pétur Sigurjónsson',
    label: 'Nafn',
  },
  {
    value: '221242-2529',
    label: 'Kennitala',
  },
  {
    value: '888-6888',
    label: 'Símanúmer',
  },
  {
    value: 'bjöggipetur42@gmail.com',
    label: 'Netfang',
  },
  {
    value: '0130-26-22124',
    label: 'Bankareikningur',
  },
];

const dummyApplicationInfo = [
  {
    value: 'Já',
    label: 'Sækir um snemmtöku ellilífeyris',
  },
  {
    value: '01.09.21',
    label: 'Frá hvaða tíma er sótt um?',
  },
  {
    value:
      'Átt rétt á greiðslum úr skyldubundnum atvinnutengdum lífeyrissjóði/um',
    label: 'Réttindi erlendis frá',
  },
  {
    value: 'Já - Býr í eigin húsnæði',
    label: 'Sækir um heimilisuppbót',
  },
];

const incomeCategoryDisplayNames: { [key: string]: string } = {
  job: 'Atvinnutekjur',
  pension: 'Lífeyrissjóðstekjur',
  investment: 'Fjármagnstekjur',
  basicPension: 'Grunnlífeyrir',
  other: 'Aðrar tekjur',
};

interface ApplicationOverviewProps {
  onSubmit?: () => void;
  onBack?: () => void;
}
export const ApplicationOverview = ({
  onSubmit,
  onBack,
}: ApplicationOverviewProps) => {
  const form = useAppSelector(selectPensionForm);
  const step0 = form?.steps[0].data;
  const step2 = form?.steps[2].data;
  const step3 = form?.steps[3].data;

  const applicationInfo = [
    {
      value: step2?.requestEarlyPension || 'Nei',
      label: 'Sækir um snemmtöku ellilífeyris',
    },
    {
      value: '01.09.21',
      label: 'Frá hvaða tíma er sótt um?',
    },
    {
      value:
        'Átt rétt á greiðslum úr skyldubundnum atvinnutengdum lífeyrissjóði/um',
      label: 'Réttindi erlendis frá',
    },
    {
      value:
        step3?.housingConditions === 'own'
          ? 'Já - Býr í eigin húsnæði'
          : 'Nei - Býr í leigu húsnæði',
      label: 'Sækir um heimilisuppbót',
    },
  ];

  const personalInfo = [
    {
      value: step0?.name,
      label: 'Nafn',
    },
    {
      value: step0?.ssn,
      label: 'Kennitala',
    },
    {
      value: step0?.phone,
      label: 'Símanúmer',
    },
    {
      value: step0?.email,
      label: 'Netfang',
    },
    {
      value: `${step0?.bank}-${step0?.hb}-${step0?.accountNumber}`,
      label: 'Bankareikningur',
    },
  ];

  return (
    <>
      <Paper header='Yfirferð persónuupplýsinga'>
        <FlexibleGrid container>
          {personalInfo.map((data, index) => {
            return (
              <GridItem item key={index} md={6}>
                <Typography variant='caption'>{data.label}</Typography>
                <Typography variant='body1'>{data.value}</Typography>
              </GridItem>
            );
          })}
        </FlexibleGrid>
        <StyledTypography variant='h6' component='h6'>
          Yfirferð umsóknar
        </StyledTypography>
        <FlexibleGrid container>
          {applicationInfo.map((data, index) => {
            return (
              <GridItem item key={index} md={6}>
                <Typography variant='caption'>{data.label}</Typography>
                <Typography variant='body1'>{data.value}</Typography>
              </GridItem>
            );
          })}
        </FlexibleGrid>
        <StyledTypography variant='h6' component='h6'>
          Yfirferð á tekjuáætlun
        </StyledTypography>
        <p>
          Þú hefur breytt tekjuáætlun á eftirfarandi vegu. Vinsamlega farið yfir
          áður en skilað er.
        </p>
        <FlexibleGrid>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Tekjuflokkur</TableCell>
                <TableCell>Tekjur á ári</TableCell>
                <TableCell>Gjaldmiðill</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {form?.steps[5].data.salary.map(
                ({ category, yearly, currency }, i) => {
                  return (
                    <TableRow key={i}>
                      <StyldTableCell>
                        {incomeCategoryDisplayNames[category] ||
                          'Vantar upplýsingar'}
                      </StyldTableCell>
                      <StyldTableCell>
                        {yearly || 'Vantar upplýsingar'}
                      </StyldTableCell>
                      <StyldTableCell>
                        {currency || 'Vantar upplýsingar'}
                      </StyldTableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </FlexibleGrid>
      </Paper>

      <FormNavigation
        onNext={onSubmit}
        onBack={onBack}
        stepStatus={{ current: 6, total: 9 }}
      />
    </>
  );
};

const GridItem = styled(Grid)`
  margin-bottom: 1rem;
`;

const StyledTypography = styled(Typography)`
  margin: 1rem 0 1rem 0;
` as typeof Typography;
const StyldTableCell = styled(TableCell)`
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  border-left: 1px solid rgba(224, 224, 224, 1);
  border-right: 1px solid rgba(224, 224, 224, 1);
  border-top: 1px solid rgba(224, 224, 224, 1);
  background-color: #fafafa;
`;
const FlexibleGrid = styled(Grid)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;

    overflow: 'scroll';
  }
` as typeof Grid;

const FlexibleTypography = styled(TableCell)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    word-break: break-all;
  }
` as typeof Typography;
