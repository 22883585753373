import { DatePicker, DatePickerProps as MuiDatePickerProps } from '@mui/lab';
import { TextField } from '@mui/material';

type DateInputProps = Omit<MuiDatePickerProps, 'renderInput'> & {
  fullWidth?: boolean
}
export const DateInput = ({ fullWidth, ...rest }: DateInputProps) => {
  return (
    <DatePicker
      inputFormat="MM/DD/yyyy"
      renderInput={(params) => <TextField fullWidth={fullWidth} {...params} />}
      {...rest}
    />
  )
}

