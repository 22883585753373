import { props } from 'cypress/types/bluebird'
import { Paper } from '../components/Paper'

interface EmptyPageProps {
  header: string
}

export const EmptyPage = ({ header }: EmptyPageProps) => {
  return <Paper header={header}></Paper>
}
