import React from 'react';
import { NavBar } from './components/NavBar';
import { Routes, Route } from 'react-router-dom';

import { Box, CssBaseline } from '@mui/material';
import { Signin } from './pages/Signin';
import { GlobalStyle } from './components/GlobalStyles';
import { Ellilifeyri } from './pages/Ellilifeyri';
import { MyInfo } from './pages/MyInfo';
import { RequireAuth } from './utils/auth/RequireAuth';
import { Home } from './pages';

export const App: React.FC = () => {
  return (
    <Box className='d-flex flex-column h-100'>
      {/* Reasonable css defaults from mui */}
      <CssBaseline />

      <GlobalStyle />

      <NavBar />

      <main>
        <Routes>
          <Route path='/innskraning' element={<Signin />} />

          <Route
            path='/umsoknir/ellilifeyri'
            element={
              <RequireAuth>
                <Ellilifeyri />
              </RequireAuth>
            }
          />
          <Route
            path='/mytr/*'
            element={
              <RequireAuth>
                <MyInfo />
              </RequireAuth>
            }
          />
          <Route
            path='/'
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />
        </Routes>
      </main>
    </Box>
  );
};
