import { Paper } from '../components/Paper';
import {
  HorizontalStepper,
  HorizontalStepProps,
} from '../components/HorizontalStepper';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  List,
  ListItem,
  styled,
  Typography,
  ListItemText,
  InputAdornment,
  ListItemIcon,
  Box,
  FormControl,
  InputLabel,
} from '@mui/material';
import { Select } from '../components/Select';
import { MenuItem } from '../components/MenuItem';
import { FormButton } from '../components/FormButton';
import { useState } from 'react';
import { TextField } from '../components/TextField';
import { FormNavigation } from '../components/FormNavigation';

import {
  useForm,
  useFieldArray,
  useWatch,
  Control,
  Controller,
} from 'react-hook-form';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Button } from '../components/Button';
import AddIcon from '@mui/icons-material/Add';
import { ArrowBack, ChevronLeft } from '@mui/icons-material';
import ArrowRight from '@mui/icons-material/ArrowRight';
import { SalaryStep2Inputs } from '../app/applications/applicationsService';

const steps: Array<HorizontalStepProps> = [
  { number: 0, label: 'Skrá tekjur', subText: '', hasError: false },
  { number: 1, label: 'Yfirferð', subText: '', hasError: false },
  { number: 2, label: 'Skila', subText: '', hasError: false },
];

const salaryTypes = {
  job: [
    <MenuItem value='salary' key='salary'>
      Laun
    </MenuItem>,
    <MenuItem value='fourPercent' key='fourPercent'>
      Iðgjald í lífeyrissjóð 4%
    </MenuItem>,
    <MenuItem value='spcecialPensionPayments' key='spcecialPensionPayments'>
      Iðgjald í sérsjóð (allt að 4%)
    </MenuItem>,
    <MenuItem value='calculatedRepayment' key='calculatedRepayment'>
      Reiknað endurgjald
    </MenuItem>,
    <MenuItem value='drivingPayments' key='drivingPayments'>
      Ökutækjastyrkur
    </MenuItem>,
    <MenuItem value='businessProfit' key='businessProfit'>
      Tekjur af atvinnurekstri
    </MenuItem>,
    <MenuItem
      value='drivingPaymentsSubtraction'
      key='drivingPaymentsSubtraction'
    >
      Frádráttur á móti ökutækjastyrk
    </MenuItem>,
    <MenuItem value='independentWorker' key='independentWorker'>
      Verktakagreiðslur sem laun (innifalið í 96)
    </MenuItem>,
    <MenuItem value='dayMoney' key='dayMoney'>
      Dagpeningar
    </MenuItem>,
    <MenuItem value='dayMoneySubtraction' key='dayMoneySubtraction'>
      Frádráttur á móti dagpeningum
    </MenuItem>,
    <MenuItem value='unemploymentBenefits' key='unemploymentBenefits'>
      Atvinnuleysisbætur
    </MenuItem>,
    <MenuItem value='carSubtitutes' key='carSubtitutes'>
      Bifreiðahlunnindi
    </MenuItem>,
    <MenuItem value='maternityLeaf' key='maternityLeaf'>
      Fæðingarorlof
    </MenuItem>,
  ],
  investment: [
    <MenuItem value='rent' key='rent'>
      Leiga af íbúðarhúsnæði
    </MenuItem>,
    <MenuItem value='stockDividents' key='stockDividents'>
      Arður af hlutabréfum
    </MenuItem>,
    <MenuItem value='foreignBankStockDividents' key='foreignBankStockDividents'>
      Arður af hlutabréfa eign í erlendum bönkum
    </MenuItem>,
    <MenuItem value='stockProfits' key='stockProfits'>
      Hagnaður af sölu hlutabréfa
    </MenuItem>,
    <MenuItem value='foreignStockDividents' key='foreignStockDividents'>
      Arður af erlendum hlutabréfum (ekki tvísköttun)
    </MenuItem>,
    <MenuItem value='rentOther' key='rentOther'>
      Leigutekjur aðrar
    </MenuItem>,
    <MenuItem value='bankInterest' key='bankInterest'>
      Vextir af innistæðum
    </MenuItem>,
    <MenuItem value='stockInterest' key='stockInterest'>
      Vextir af verðbréfum
    </MenuItem>,
    <MenuItem value='otherProfits' key='otherProfits'>
      Annar söluhagnaður
    </MenuItem>,
    <MenuItem value='foreignBankInterest' key='foreignBankInterest'>
      Vextir af innistæðum í erlendum bönkum
    </MenuItem>,
  ],
  pension: [
    <MenuItem value='pension' key='pension'>
      Lífeyrissjóður
    </MenuItem>,
    <MenuItem value='specialPension' key='specialPension'>
      Lífeyrisgreiðslur úr séreignasjóðum
    </MenuItem>,
    <MenuItem value='partnerPension' key='partnerPension'>
      Makalífeyrir
    </MenuItem>,
    <MenuItem
      value='specialPayoutSpecialPension'
      key='specialPayoutSpecialPension'
    >
      Sérstök útborgun úr séreignasjóðum
    </MenuItem>,
    <MenuItem value='foreignPension' key='foreignPension'>
      Erlendur lífeyrir
    </MenuItem>,
  ],
  other: [
    <MenuItem value='taxableSalary' key='taxableSalary'>
      Skattskyldar tekjur (óskilgreint)
    </MenuItem>,
    <MenuItem value='otherHelpsubtraction' key='otherHelpsubtraction'>
      Frádráttur á móti öðrum styrkjum
    </MenuItem>,
    <MenuItem value='socialHelp' key='socialHelp'>
      Félagsleg aðstoð
    </MenuItem>,
    <MenuItem value='studyandresearchgrant' key='studyandresearchgrant'>
      Náms- og vísindastyrkur
    </MenuItem>,
    <MenuItem value='foreignSalary' key='foreignSalary'>
      Erlendar tekjur
    </MenuItem>,
    <MenuItem value='helpSubtraction' key='helpSubtraction'>
      Frádráttur á móti styrkjum
    </MenuItem>,
    <MenuItem value='otherSubsidies' key='otherSubsidies'>
      Önnur hlunnindi
    </MenuItem>,
    <MenuItem value='estimatedSalary' key='estimatedSalary'>
      Áætlaður tekjustofn
    </MenuItem>,
    <MenuItem value='unionPayment' key='unionPayment'>
      Greiðslur frá stéttarfélagi
    </MenuItem>,
    <MenuItem value='maternityGrant' key='maternityGrant'>
      Fæðingarstyrkur
    </MenuItem>,
    <MenuItem value='estimatedAddon' key='estimatedAddon'>
      Áætlað álag
    </MenuItem>,
    <MenuItem value='insurancePayment' key='insurancePayment'>
      Greiðslur frá tryggingarfélagi
    </MenuItem>,
  ],
  basicPension: [
    <MenuItem value='foreignBasicPension' key='foreignBasicPension'>
      Erlendur grunnlífeyrir
    </MenuItem>,
  ],
};

interface SalaryCategory {
  index: number;
  category: string;
}

interface SalaryStep2Props {
  onSubmit?: (inputs: SalaryStep2Inputs) => void;
  onBack?: () => void;
  handleSaveDataOnBlur: (
    key: string,
    value: string | number | boolean | any[]
  ) => void;
  existingData: SalaryStep2Inputs;
}

export const SalaryStep2 = ({
  onSubmit: propOnSubmit,
  onBack,
  handleSaveDataOnBlur,
  existingData,
}: SalaryStep2Props) => {

  // prettier-ignore
  const [activeSalaryCategories, setActiveSalaryCategories] = useState<SalaryCategory[]>(existingData?.salary?.length ? existingData.salary.map(({category}, i) => ({ index: i, category})) : [{ index: 0, category: 'job' }]);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SalaryStep2Inputs>({
    defaultValues: {
      // deep-copy
      salary: existingData?.salary?.map((x) => ({ ...x })) || [],
      comment: existingData.comment,
    },
    mode: 'onBlur',
  });
  const { fields, append, remove } = useFieldArray({
    name: 'salary',
    control,
  });
  const onSubmit = (data: SalaryStep2Inputs) => {
    propOnSubmit && propOnSubmit(data);
  };

  const getSelectMenuItems = (index: number) => {
    const thisCat = activeSalaryCategories.find((cat) => cat.index === index);
    if (thisCat) {
      //@ts-ignore
      return salaryTypes[thisCat.category];
    } else return;
  };

  const handleSaveFormState = (key: string, value: string) => {
    if (key.startsWith('salary.')) {
      const [_, index = 0, propertyKey] = key.split('.');
      const salaries = fields.map(({ id, ...data }) => {
        return {
          ...data,
        };
      });

      //@ts-ignore
      salaries[+index][propertyKey] = value;
      handleSaveDataOnBlur('salary', salaries);
    } else if (key.startsWith('delete-salary-entry')) {
      const salaries = fields
        .filter((_, i) => i !== (value as unknown as number))
        .map(({ id, ...data }) => {
          return {
            ...data,
          };
        });

      handleSaveDataOnBlur('salary', salaries);
    } else {
      handleSaveDataOnBlur(key, value);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper header='Tekjuáætlun'>
        {/* <HorizontalStepper steps={steps} activeStep={steps[0]} /> */}
        <Box>
          <Typography component='p' variant='body2'>
            Breyta tekjuáætlun, leiðbeiningar
          </Typography>
          <List>
            <ListItem>
              <StyledListItemIcon>
                <ArrowRightIcon sx={{ color: 'secondary.main' }} />
              </StyledListItemIcon>
              <StyledListItemText primary='Hér fyrir neðan getur þú breytt upphæðum og bætt við tekjum' />
            </ListItem>
            <ListItem>
              <StyledListItemIcon>
                <ArrowRightIcon sx={{ color: 'secondary.main' }} />
              </StyledListItemIcon>
              <StyledListItemText primary='Skrá skal heildartekjur fyrir skatt í tekjuáætlun.' />
            </ListItem>
            <ListItem>
              <StyledListItemIcon>
                <ArrowRightIcon sx={{ color: 'secondary.main' }} />
              </StyledListItemIcon>
              <StyledListItemText primary='Fjármagnstekjur eru sameiginlegar hjá hjónum og skal skrá heildar fjármagnstekjur hjóna í tekjuáætlun' />
            </ListItem>
            <ListItem>
              <StyledListItemIcon>
                <ArrowRightIcon sx={{ color: 'secondary.main' }} />
              </StyledListItemIcon>
              <StyledListItemText primary='Ef um maka á lífeyri er að ræða verða greiðslur hans einnig endurreiknaðar ef um fjármagnstekjur er að ræða' />
            </ListItem>
            <ListItem>
              <StyledListItemIcon>
                <ArrowRightIcon sx={{ color: 'secondary.main' }} />
              </StyledListItemIcon>
              <StyledListItemText primary='Heimilt er að skrá atvinnutekjur á þá mánuði sem þeirra er aflað. Reiknast þá þær atvinnutekjur eingöngu í þeim mánuði' />
            </ListItem>
          </List>
        </Box>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '23% 28% 15% 14% 14% 4%',
            gridColumnGap: '8px',
            marginBottom: '17px',
            marginTop: '50px',
          }}
        >
          <Typography component='span' variant='subtitle2'>
            Tekjuflokkur
          </Typography>
          <Typography component='span' variant='subtitle2'>
            Tekjutegund
          </Typography>
          <Typography component='span' variant='subtitle2'>
            Tekjur á ári
          </Typography>
          <Typography component='span' variant='subtitle2'>
            Tekjur á mán
          </Typography>
          <Typography component='span' variant='subtitle2'>
            Gjaldmiðill
          </Typography>
          <Typography component='span' variant='subtitle2'></Typography>
        </Box>

        {fields.map((field, index) => {
          return (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '23% 28% 15% 14% 14% 4%',
                gridColumnGap: '8px',
              }}
              key={field.id}
            >
              <Box sx={{ height: '36px' }}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name={`salary.${index}.category`}
                    render={({ field: { onChange, onBlur, ref, ...rest } }) => (
                      <SmallerSelect
                        inputProps={{ 'aria-label': 'Without label' }}
                        value={
                          activeSalaryCategories.find(
                            (cat) => cat.index === index
                          )?.category
                        }
                        onChange={(value) => {
                          onChange(value);
                          const newActiveSalaryCategories =
                            activeSalaryCategories.map((cat) => {
                              if (cat.index === index) {
                                return {
                                  ...cat,
                                  category: value.target.value as string,
                                };
                              } else {
                                return cat;
                              }
                            });
                          setActiveSalaryCategories(newActiveSalaryCategories);
                        }}
                        onBlur={() => {
                          onBlur();
                          handleSaveFormState(rest.name, rest.value);
                        }}
                      >
                        <MenuItem value=''></MenuItem>
                        <MenuItem value='job'>Atvinnutekjur</MenuItem>
                        <MenuItem value='pension'>Lífeyrissjóðstekjur</MenuItem>
                        <MenuItem value='investment'>Fjármagnstekjur</MenuItem>
                        <MenuItem value='basicPension'>Grunnlífeyrir</MenuItem>
                        <MenuItem value='other'>Aðrar tekjur</MenuItem>
                      </SmallerSelect>
                    )}
                  />
                </FormControl>
              </Box>
              <Box sx={{ height: '36px' }}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name={`salary.${index}.type`}
                    render={({ field: { onBlur, ref, ...rest } }) => (
                      <SmallerSelect
                        inputProps={{ 'aria-label': 'Without label' }}
                        onBlur={() => {
                          onBlur();
                          handleSaveFormState(rest.name, rest.value);
                        }}
                        {...rest}
                      >
                        <MenuItem value=''></MenuItem>
                        {getSelectMenuItems(index)}
                      </SmallerSelect>
                    )}
                  />
                </FormControl>
              </Box>
              <Box sx={{ height: '36px' }}>
                <Controller
                  control={control}
                  name={`salary.${index}.yearly`}
                  render={({ field: { onBlur, ref, ...rest } }) => (
                    <SmallerTextInput
                      inputRef={ref}
                      type='number'
                      onBlur={() => {
                        onBlur();
                        handleSaveFormState(rest.name, rest.value);
                      }}
                      {...rest}
                    />
                  )}
                />
              </Box>
              <Box sx={{ height: '36px' }}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name={`salary.${index}.monthly`}
                    render={({ field: { onBlur, ref, ...rest } }) => (
                      <SmallerSelect
                        inputProps={{ 'aria-label': 'Without label' }}
                        onBlur={() => {
                          onBlur();
                          handleSaveFormState(rest.name, rest.value);
                        }}
                        {...rest}
                      >
                        <MenuItem value='10'>10</MenuItem>
                        <MenuItem value='20'>20</MenuItem>
                      </SmallerSelect>
                    )}
                  />
                </FormControl>
              </Box>
              <Box sx={{ height: '36px' }}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name={`salary.${index}.yearly`}
                    render={({ field: { onBlur, ref, ...rest } }) => (
                      <SmallerTextInput
                        inputRef={ref}
                        type='number'
                        onBlur={() => {
                          onBlur();
                          handleSaveFormState(rest.name, rest.value);
                        }}
                        {...rest}
                      />
                    )}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  height: '36px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <StyledButton
                  type='button'
                  size='small'
                  variant='text'
                  onClick={() => {
                    remove(index);
                    const newActiveSalaryCategories =
                      activeSalaryCategories.filter(
                        (item) => item.index !== index
                      );
                    setActiveSalaryCategories(newActiveSalaryCategories);
                    handleSaveFormState(
                      'delete-salary-entry',
                      index as unknown as string
                    );
                  }}
                >
                  <CancelRoundedIcon sx={{ color: 'rgba(0, 0, 0, 0.54);' }} />
                </StyledButton>
              </Box>
            </Box>
          );
        })}

        <Button
          type='button'
          variant='outlined'
          color='primary'
          startIcon={<AddIcon />}
          sx={{ marginTop: '30px' }}
          onClick={() => {
            append({
              category: 'job',
              type: '',
              yearly: '',
              monthly: '',
              currency: 'IKR',
            });
            setActiveSalaryCategories([
              { index: activeSalaryCategories.length, category: 'job' },
              ...activeSalaryCategories,
            ]);
          }}
        >
          BÆTA VIÐ LÍNU
        </Button>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '50px',
          }}
        >
          <Typography
            component='label'
            variant='subtitle2'
            sx={{ marginBottom: '15px' }}
          >
            Athugasemdir
          </Typography>
          <Controller
            control={control}
            name='comment'
            render={({ field: { onBlur, ref, ...rest } }) => (
              <TextField
                inputRef={ref}
                label='Skrifa hér'
                onBlur={() => {
                  onBlur();
                  handleSaveFormState(rest.name, rest.value);
                }}
                {...rest}
              />
            )}
          />
        </Box>
        <ListWrapper>
          <Typography variant='body2' component='p'>
            Áður en þú skilar tekjuáætluninni þá viljum við vekja athygli þína á
            eftirfarandi atriðum.
          </Typography>
          <List>
            <ListItem>
              <StyledListItemIcon>
                <ArrowRightIcon sx={{ color: 'secondary.main' }} />
              </StyledListItemIcon>
              <StyledListItemText primary='Greiðslur þínar verða reiknaðar út frá þeim upplýsingum sem þú hefur gefið upp.' />
            </ListItem>
            <ListItem>
              <StyledListItemIcon>
                <ArrowRightIcon sx={{ color: 'secondary.main' }} />
              </StyledListItemIcon>
              <StyledListItemText primary='Fjármagnstekjur eru sameiginlegar tekjur hjóna. Ef em maki er líka á lífeyri verða greiðslur hans endurreiknaðar.' />
            </ListItem>
            <ListItem>
              <StyledListItemIcon>
                <ArrowRightIcon sx={{ color: 'secondary.main' }} />
              </StyledListItemIcon>
              <StyledListItemText
                primary='Það er á ábyrgð lífeyrisþega að tekjuáætlun sé rétt of að nauðsynlegar upplýsingar séu gefnar til að hægt sé ap 
                ákvarða réttar greiðslur.'
              />
            </ListItem>
            <ListItem>
              <StyledListItemIcon>
                <ArrowRightIcon sx={{ color: 'secondary.main' }} />
              </StyledListItemIcon>
              <StyledListItemText
                primary='Þrátt fyrir það höfum við eftirlit með að tekjur séu í samræmi við rauntekjur og getur verið að tekjuáætlun þinn 
                verði breytt á þeim grundvelli. (Lög um almannatryggingar nr. 100/2007)'
              />
            </ListItem>
          </List>
          <Typography variant='body2' component='p'>
            Greiðslur eru enurreiknaðar þegar framtal vegna greiðsluárs liggur
            fyrir sem tryggir að allir fá réttar greiðslur í samræmi við
            endanlegar tekjur.
          </Typography>
        </ListWrapper>
      </Paper>

      <FormNavigation
        onNext={handleSubmit(onSubmit)}
        onBack={onBack}
        stepStatus={{ current: 5, total: 9 }}
      />
    </form>
  );
};

const StyledButton = styled(Button)`
  min-width: auto;
  padding: 0 !important;

  &:hover {
    background-color: transparent;
  }
`;

const SmallerSelect = styled(Select)`
  > div {
    padding: 6.5px 13px;
  }
`;

const SmallerTextInput = styled(TextField)`
  .MuiInputBase-input {
    padding: 6.5px 13px;
  }
`;

const ListWrapper = styled(Box)`
  margin-top: 45px;
`;

const StyledListItemText = styled(ListItemText)`
  > span {
    font-size: 14px;
  }
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: auto;
  margin-right: 10px;
`;
