import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LanguageIcon from '@mui/icons-material/Language'
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/store'
import { didSignout, selectUser } from '../../app/users/usersSlice'
import { UserOptionsMenu } from '../UserOptionsMenu'
import { Button } from './../Button'
import { SVGlogo } from './../svg/SVGlogo'
import MenuIcon from '@mui/icons-material/Menu'
import Settings from '@mui/icons-material/Settings'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import CloseIcon from '@mui/icons-material/Close'
import { Link } from 'react-router-dom'

const links = [
  { label: 'Þú hjá TR', href: '/mytr/paymentplan' },
  { label: 'Umsóknir', href: '/umsoknir/ellilifeyri' },
  { label: 'Hafa samband', href: '/' },
  { label: 'Senda gögn', href: '/' },
  { label: 'Aðstoð', href: '/' },
]
const drawerLinks = ['Tungumál', 'Stillingar', 'Útskrá']

export const NavBar = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [userOptionsAnchorEl, setUserOptionsAnchorEl] =
    React.useState<null | HTMLElement>(null)
  const open = Boolean(userOptionsAnchorEl)
  const handleUserOptionsClick = (event: React.MouseEvent<HTMLElement>) => {
    setUserOptionsAnchorEl(event.currentTarget)
  }
  const handleUserOptionsClose = () => {
    setUserOptionsAnchorEl(null)
  }
  const handleSignoutClick = () => {
    handleUserOptionsClose()
    setMobileMenuOpen(false)
    dispatch(didSignout())
  }

  return (
    <StyledAppBar position='static'>
      <AppBarContent
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <StyledIconButtons
         
        >
          <Link to='/'>
            <Box style={{display: 'flex'}}>
              <SVGlogoMain  />
              {/* <SVGlogo width={30} height={undefined} /> */}
            </Box>
          </Link>
        </StyledIconButtons>
        {/* <ContainerLogo>
          <Link to='/'>
            <SVGlogo />
          </Link>
        </ContainerLogo> */}

        {/* DESKTOP MENU */}
        <ContainerNavigation style={{marginRight: 140}}>
          {links.map((page, index) => (
            <NavigationButton
              key={index}
              variant='text'
              size='large'
              href={page.href}
            >
              {page.label}
            </NavigationButton>
          ))}
        </ContainerNavigation>

        {/* <div style={{ flexGrow: 1 }}></div> */}

        <ContainerActions>
          {user !== null && (
            <ButtonBox>
              <Button
                variant='contained'
                color='secondary'
                size='small'
                sx={{ boxShadow: 0, minWidth: 'initial' }}
                startIcon={<AccountCircleIcon />}
                onClick={handleUserOptionsClick}
              >
                {user.fullName.split(/\s+/).shift()}
              </Button>
              <UserOptionsMenu
                open={open}
                anchorEl={userOptionsAnchorEl}
                onClose={handleUserOptionsClose}
                onClickSignout={handleSignoutClick}
              />
            </ButtonBox>
          )}

          {/* MOBILE MENU */}
          <StyledBurgerButton
            variant='text'
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <MenuIcon />
          </StyledBurgerButton>
          <Drawer
            anchor='right'
            open={mobileMenuOpen}
            onClose={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <DrawerHeader>
              <Button
                variant='text'
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <CloseIcon />
              </Button>
            </DrawerHeader>
            <StyledDrawerList>
              {links.map((text, index) => (
                <StyleLink to={text.href} key={index}>
                  <ListItem
                    key={index}
                    button
                    onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                  >
                    <StyledListItemText primary={text.label} />
                  </ListItem>
                </StyleLink>
              ))}
            </StyledDrawerList>
            <Divider style={{ margin: '20px 0' }} />
            <StyledDrawerList>
              {drawerLinks.map((text, index) => {
                if(text === "Útskrá" && user == null){
                  return <></>
                }
                else return (
                  //@ts-ignore
                  <StyledSubListItem
                    key={index}
                    onClick={index === 2 && handleSignoutClick}
                    button
                  >
                    <ListItemIcon>
                      {index === 0 && <NavBarLanguageIcon />}
                      {index === 1 && (
                        <Settings
                          fontSize='medium'
                          sx={{ color: 'primary.main' }}
                        />
                      )}
                      {index === 2 && (
                        <CancelRoundedIcon sx={{ color: 'warning.main' }} />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </StyledSubListItem>
                )
              })}
            </StyledDrawerList>
          </Drawer>
        </ContainerActions>
      </AppBarContent>
    </StyledAppBar>
  )
}

const StyledBurgerButton = styled(Button)`
  ${({ theme }) => theme.breakpoints.up('lg')} {
    display: none;
  }
`
const StyleLink = styled(Link)`
  color: unset;
  text-decoration: unset;
` as typeof Link
const DrawerHeader = styled(Box)`
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const StyledSubListItem = styled(ListItem)`
  padding-left: 65px;
`

const StyledListItemText = styled(ListItemText)`
  padding-left: 65px;
  > span {
    font-weight: bold;
    line-height: 160%;
    font-size: 20px;
  }

  &.active {
    > span {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`

const StyledDrawerList = styled(List)`
  width: 320px;
`

const NavigationButton = styled(Button)`
  color: ${(props) => props.theme.palette.text.primary};
  text-transform: none;
`

const StyledAppBar = styled(Box)`
  background-color: #ffffff;
  height: 80px;
  display: flex;
  box-shadow: none;
`

const AppBarContent = styled(Box)`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  padding: 0 ${(props) => props.theme.spacing(3)};
`

const ContainerNavigation = styled(Box)`
  margin-right: ${(props) => props.theme.spacing(3)};
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    display: none;
  }
`

const NavBarLanguageIcon = styled(LanguageIcon)`
  color: ${(props) => props.theme.palette.primary.main};
`

const ContainerLogo = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 132px;
`

const ContainerActions = styled(Box)`
  display: flex;
  align-items: center;
`

const ButtonBox = styled(Box)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`

const SVGlogoMain = styled(SVGlogo)`
  ${({ theme }) => theme.breakpoints.down('sm')}{
    width: 100px;
  }
`
const StyledIconButtons = styled(Button)`
  &:hover {
    background-color: unset;
}
`;