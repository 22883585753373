import {
  FormControl,
  FormControlProps,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  styled,
  RadioGroupProps,
} from '@mui/material';
import React, { createRef } from 'react';

interface ItemProps {
  value: string | boolean;
  label: string;
}

interface RadioBoxProps {
  label: string;
  items: Array<ItemProps>;
  isRow?: boolean;
  radioGroup?: RadioGroupProps;
  formControl?: FormControlProps;
  style?: React.CSSProperties;
}
export const RadioBox = ({
  label,
  items,
  isRow,
  radioGroup,
  formControl,
  style,
}: RadioBoxProps) => {
  const ref = createRef();
  return (
    <FormControl style={style} {...formControl}>
      <FormLabel>{label}</FormLabel>
      <RadioGroup ref={ref} row={!!isRow} {...radioGroup}>
        {items.map((item, index) => (
          <StyledFormControlLabel
            key={index}
            value={item.value}
            control={<Radio />}
            label={item.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  font-weight: 500;
`;
