import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Settings from '@mui/icons-material/Settings';
import Cancel from '@mui/icons-material/Cancel';

interface UserOptionsMenuProps extends MenuProps {
  onClickSignout?: () => void;
}

export const UserOptionsMenu = (props: UserOptionsMenuProps) => {
  const { onClickSignout = () => '', ...rest } = props;
  return (
    <Menu
      {...rest}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      sx={{ marginTop: '8px' }}
    >
      <MenuItem>
        <ListItemIcon>
          <Settings fontSize='medium' />
        </ListItemIcon>
        <ListItemText>Stillingar</ListItemText>
      </MenuItem>
      <MenuItem onClick={onClickSignout}>
        <ListItemIcon>
          <Cancel fontSize='medium' color='warning' />
        </ListItemIcon>
        <ListItemText>Útskráning</ListItemText>
      </MenuItem>
    </Menu>
  );
};
