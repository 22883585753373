import { useState } from 'react';
import {
  Grid,
  Typography,
  styled,
  FormControl,
  InputLabel,
  FormHelperText,
  Box,
  IconButton,
} from '@mui/material';
import { Paper } from '../components/Paper';
import { RadioBox } from '../components/RadioBox';
import { Select } from '../components/Select';
import { Button } from '../components/Button';
import { Alert } from '../components/Alert';
import { MenuItem } from '../components/MenuItem';
import { DateInput } from '../components/DateInput';
import moment from 'moment';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { countries } from '../constants/countries';
import DeleteIcon from '@mui/icons-material/Delete';
import { FormNavigation } from '../components/FormNavigation';
import { ParentPaymentInputs } from '../app/applications/applicationsService';

type Inputs = {
  hasCheckedPensionFunds: boolean;
  requestEarlyPension: boolean;
  hasLivedAndWorkedAbroad: boolean;
  country: string;
  dateFrom: string;
  dateTo: string;
};

type AbroadEntries = ParentPaymentInputs['abroadEntries'];

interface ParentPaymentProps {
  onSubmit?: (inputs: ParentPaymentInputs) => void;
  onBack?: () => void;
  handleSaveDataOnBlur: (
    key: string,
    value: string | number | boolean | any[]
  ) => void;
  existingData: ParentPaymentInputs;
}
export const ParentPayment = ({
  onSubmit: propOnSubmit,
  onBack,
  handleSaveDataOnBlur: handleSaveFormState,
  existingData,
}: ParentPaymentProps) => {
  const { handleSubmit, getValues, control, watch } = useForm<Inputs>({
    defaultValues: {
      hasCheckedPensionFunds: existingData.hasCheckedPensionFunds,
      hasLivedAndWorkedAbroad: existingData.hasLivedAndWorkedAbroad,
      requestEarlyPension: existingData.requestEarlyPension,
      country: '',
      dateFrom: '',
      dateTo: '',
    },
  });

  // useEffect(() => {
  //   setAbroadEntries(existingData.abroadEntries.map((entry) => ({ ...entry })));
  // }, []);

  const hasLivedAndWorkedAbroad = watch('hasLivedAndWorkedAbroad');

  const [abroadEntries, setAbroadEntries] = useState<AbroadEntries>([]);
  const handleAddAbroadEntry = () => {
    const [dateFrom, dateTo, country] = getValues([
      'dateFrom',
      'dateTo',
      'country',
    ]);

    setAbroadEntries([
      ...abroadEntries,
      {
        dateFrom: moment(dateFrom).format('MM/DD/yyyy'),
        dateTo: moment(dateTo).format('MM/DD/yyyy'),
        country,
      },
    ]);
  };
  const handleRemoveAbroadEntry = (index: number) => {
    const result = [
      ...abroadEntries.slice(0, index),
      ...abroadEntries.slice(index + 1),
    ];
    setAbroadEntries(result);
    handleSaveFormState('abroadEntries', result);
  };

  const onSubmit: SubmitHandler<Inputs> = ({
    hasCheckedPensionFunds,
    hasLivedAndWorkedAbroad,
    requestEarlyPension,
  }) => {
    // README: Here is the object for the server
    const result: ParentPaymentInputs = {
      hasCheckedPensionFunds: hasCheckedPensionFunds,
      hasLivedAndWorkedAbroad: hasLivedAndWorkedAbroad,
      requestEarlyPension: requestEarlyPension,
      abroadEntries,
    };

    propOnSubmit && propOnSubmit(result);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Paper header={'Upplýsingar'}>
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <TypographyFirst
              style={{ marginBottom: 48 }}
              variant='body1'
              component='p'
            >
              Réttur til ellilífeyris er við 67 ára aldur. Fullur réttur á
              Íslandi miðast við 40 ára búsetu á aldrinum 16-67 ára. Þeir sem
              haga búið og starfað erlendis geta átt rétt á lífeyrisgreiðslum
              þaðan á móti greiðslum á Íslani. Ellilífeyrir, tekjutrygging og
              heimilisuppbót hækka um 0,5% fyrir hvem mánuð sem umsókn er
              frestað til 72 ára aldurs. Umsókn um ellilífeyri rífur heimild til
              frestunar. Tekjuáætlun þarf að fylgja umsókn um ellilífeyri.
            </TypographyFirst>

            <Typography variant='h6' component='p'>
              Hefur þú kannað rétt þinn hjá lífeyrissjóðum?
            </Typography>

            <Typography style={{ paddingTop: 8 }} variant='body2' component='p'>
              Framvísa þarf gögnum sem staðfesta áunnin réttindi eða réttleysi
              ef greiðslur frá lífeyrissjóði/um eru ekki hafnar.
            </Typography>

            <Controller
              control={control}
              name='hasCheckedPensionFunds'
              render={({ field }) => (
                <RadioBox
                  style={{ marginBottom: 48, paddingTop: 24 }}
                  isRow
                  label={''}
                  radioGroup={{ ...field }}
                  items={[
                    { value: true, label: 'Já' },
                    { value: false, label: 'Nei' },
                  ]}
                />
              )}
            />

            <Typography variant='h6' component='p'>
              Hefur þú búið og/ eða starfað erlendis?
            </Typography>

            <Controller
              control={control}
              name='hasLivedAndWorkedAbroad'
              render={({ field }) => (
                <RadioBox
                  isRow={true}
                  label={''}
                  radioGroup={{ ...field }}
                  style={{ paddingTop: 24 }}
                  items={[
                    { value: 'true', label: 'Já' },
                    { value: 'false', label: 'Nei' },
                  ]}
                />
              )}
            />
          </Grid>

          {hasLivedAndWorkedAbroad && (
            <>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name='country'
                  render={({
                    field: { ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <FormControl fullWidth error={!!error}>
                      <InputLabel>Veldu land</InputLabel>
                      <Select inputRef={ref} label='Veldu land' {...rest}>
                        <MenuItem value={''}>---</MenuItem>
                        {countries.map((value, index) => {
                          return (
                            <MenuItem key={index} value={value}>
                              {value}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {error?.type === 'required' && (
                        <FormHelperText>
                          Vantar að filla inn land
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid container item columnSpacing={2}>
                <Grid item xs={5}>
                  <Controller
                    control={control}
                    name='dateFrom'
                    render={({
                      field: { ref, ...rest },
                      fieldState: { error },
                    }) => (
                      <StyledDateInput
                        label={'Tímabil frá'}
                        fullWidth
                        inputRef={ref}
                        {...rest}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={5}>
                  <Controller
                    control={control}
                    name='dateTo'
                    render={({
                      field: { ref, ...rest },
                      fieldState: { error },
                    }) => (
                      <DateInput
                        label={'Tímabil til'}
                        fullWidth
                        inputRef={ref}
                        {...rest}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={2}>
                  <InlineButton
                    variant='outlined'
                    size='small'
                    onClick={handleAddAbroadEntry}
                  >
                    Bæta við
                  </InlineButton>
                </Grid>
              </Grid>

              {!!abroadEntries.length && (
                <Grid item xs={12}>
                  {/* TODO: new reusable component for this */}
                  {abroadEntries.map((item, index) => (
                    <List key={index}>
                      <ListRow>
                        <ListItem>
                          {index === 0 && (
                            <ListLabel variant='body2' component='div'>
                              Land:
                            </ListLabel>
                          )}
                          <ListItemCell>{item.country}</ListItemCell>
                        </ListItem>
                        <ListItem>
                          {index === 0 && (
                            <ListLabel variant='body2' component='div'>
                              Tímabil frá:
                            </ListLabel>
                          )}
                          <ListItemCell>{item.dateFrom}</ListItemCell>
                        </ListItem>
                        <ListItem>
                          {index === 0 && (
                            <ListLabel variant='body2' component='div'>
                              Tímabil til:
                            </ListLabel>
                          )}
                          <ListItemCell>{item.dateTo}</ListItemCell>
                        </ListItem>
                        <ListItemActionButton
                          onClick={() => handleRemoveAbroadEntry(index)}
                        >
                          <DeleteIcon
                            style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                          />
                        </ListItemActionButton>
                      </ListRow>
                    </List>
                  ))}
                </Grid>
              )}
            </>
          )}

          <Grid item xs={12}>
            <Typography variant='h6' component='p'>
              Ég sæki um snemmtöku ellilífeyris?
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name='requestEarlyPension'
              render={({ field }) => (
                <RadioBox
                  isRow
                  label={''}
                  radioGroup={{ ...field }}
                  items={[
                    { value: 'true', label: 'Já' },
                    { value: 'false', label: 'Nei' },
                  ]}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Alert severity='info'>
              Sækja þarf um hjá lífeyrissjóði áður en sótt er um hjá TR.
              Framvísa þarf gögnum sem staðfesta áunnin réttindi eða réttleysi.
            </Alert>
          </Grid>
        </Grid>
      </Paper>

      <FormNavigation
        onNext={handleSubmit(onSubmit)}
        onBack={onBack}
        stepStatus={{ current: 2, total: 9 }}
      />
    </form>
  );
};

const List = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const ListRow = styled(Box)`
  width: 100%;
  display: flex;
  align-items: flex-end;
`;

const ListItem = styled(Box)`
  flex-grow: 1;
`;

const ListItemCell = styled(Box)`
  display: flex;
  align-items: center;
  height: 40px;
  background-color: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 0 10px;
`;

const ListItemActionButton = styled(IconButton)`
  height: 40px;
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: #fafafa;
`;

const ListLabel = styled(Typography)`
  padding-left: 10px;
  padding-bottom: 4px;
  width: 100%;
` as typeof Typography;

const TypographyFirst = styled(Typography)`
  width: 100%;
` as typeof Typography;

const InlineButton = styled(Button)`
  width: 100%;
  height: 100%;
`;

const StyledDateInput = styled(DateInput)`
  width: 100%;
`;
