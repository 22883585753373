import { CheckCircle } from '@mui/icons-material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {
  Box, Button, Typography
} from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import { Paper } from '../components/Paper/Paper'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../app/store'
import { selectUser } from '../app/users/usersSlice'

export const FinalOverview = () => {
  const user = useAppSelector(selectUser)

  return (
    <>
      <Paper header=''>
        <Typography 
          sx={{ textAlign: 'center' }}
          variant='h4' 
          component='h4'
        >
          <CheckCircle color='primary' fontSize='large' />
          Umsókn vistuð og send í vinnslu
        </Typography>

        <StyledBox>
          <Button
            variant="contained"
            color="primary"
            href={`https://trauditmockbackend.azurewebsites.net/api/pension-application/download/${user?.nationalId}`}
          >
            Sækja pdf
          </Button>
        </StyledBox>
      </Paper>

      <div style={{ width: '100%', textAlign: 'right', paddingTop: '32px' }}>
        <Button
          component={Link}
          endIcon={<ArrowForwardIcon />}
          variant="contained"
          color="secondary"
          to='/'
        >
          Aftur á forsíðu
        </Button>
      </div>
    </>
  )
}

const StyledBox = styled(Box)`
    text-align: center;
    padding: 25px;
`
