import { Box, Button, styled, Typography } from "@mui/material"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

interface FormNavigationProps {
  onNext?: () => void
  nextLabel?: string
  onBack?: () => void
  backLabel?: string
  stepStatus?: { current: number, total: number }
}
export const FormNavigation = ({
  onNext,
  nextLabel = 'Áfram',
  onBack,
  backLabel = 'Tilbaka',
  stepStatus,
}: FormNavigationProps) => {

  return (
    <Container>
      {onBack && (
        <Button
          variant="text"
          color="primary"
          startIcon={<ArrowBackIcon />}
          onClick={onBack}
        >
          {backLabel}
        </Button>
      )}

      <Status>
        {stepStatus && (
          <div className="content">
            <Typography color="text.primary" variant="h6" component="div">{stepStatus.current}/{stepStatus.total}</Typography>
          </div>
        )}
      </Status>

      {onNext && (
        <Button 
          variant="contained" 
          color="secondary"
          endIcon={<ArrowForwardIcon />}
          onClick={onNext}
        >
          {nextLabel}
        </Button>
      )}
    </Container>
  )
}

const Status = styled(Box)`
  flex-grow: 1;

  .content {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  ${props => props.theme.breakpoints.up('lg')} {
    .content {
      display: none;
    }
  }
`

const Container = styled(Box)`
  padding-top: 32px;
  display: flex;
`