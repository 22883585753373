import { Box, Paper as MuiPaper, PaperProps, PaperTypeMap, styled, Typography } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { motion } from "framer-motion"


const variants = {
  visible: { opacity: 1, y: 0, },
  hidden: { opacity: 0, y: 10 }
}

interface PaperPropsMain extends PaperProps {
  header: string
  subText?: string
  noAnimation?: boolean
}
export const Paper: React.FC<PaperPropsMain> = ({ 
  children, 
  noAnimation, 
  header,
  subText,
  ...rest 
}) => {
  return (
    <motion.div
      animate={'visible'}
      initial={noAnimation === true ? 'visible' : 'hidden'}
      variants={variants}
      transition={{ duration: 0.4 }}>
      <AppPaper {...rest}>
        <Box pb={6}>
          <Typography variant='h5' component='h3'>{header}</Typography>
          {subText && (<Typography sx={{ pt: 3 }} variant='body1' component="p">{subText}</Typography>)}
        </Box>
        {children}
      </AppPaper>
    </motion.div>
  );
}

const AppPaper = styled(MuiPaper)`
  width: 100%;
  box-shadow: none;
  

  padding: 24px 24px 48px 24px;

  ${props => props.theme.breakpoints.up('sm')} {
    padding: 32px 32px 48px 32px;
  }

  ${props => props.theme.breakpoints.up('lg')} {
    padding: 64px 64px 80px 64px;
  }
`;