import React from 'react';
import styled from 'styled-components';
import { Button, Grid } from '@mui/material';
import { Paper } from '../components/Paper';
import { FormButton } from '../components/FormButton';
import { TextField as TextFieldApp } from '../components/TextField';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormNavigation } from '../components/FormNavigation';
import { FormPersonalInfoInputs } from '../app/applications/applicationsService';

interface FormPersonalInfoProps {
  onSubmit?: (inputs: FormPersonalInfoInputs) => void;
  handleSaveDataOnBlur: (key: string, value: string) => void;
  existingData: FormPersonalInfoInputs;
}
export const FormPersonalInfo = ({
  onSubmit: propOnSubmit,
  handleSaveDataOnBlur,
  existingData,
}: FormPersonalInfoProps) => {
  const { handleSubmit, control } = useForm<FormPersonalInfoInputs>({
    defaultValues: {
      ...existingData,
    },
  });

  const onSubmit: SubmitHandler<FormPersonalInfoInputs> = (data) => {
    // Only called when inputs have valid data.
    propOnSubmit && propOnSubmit(data);
  };

  /**
   * When the user blurs an input we want to save the form state
   * so he can continue on other devices
   * TODO: this could be a generic hook
   */
  const handleSaveFormState = (name: string, value: string) => {
    handleSaveDataOnBlur(name, value);
    // If you want to update individual fields here you have name and value
    // example: dispatch('FORM_STATE_UPDATE', { value: { form: 'personInfo', { [name]: value } } })

    // README: if you want all the form state instead of just this field.  You call this.
    // getValues()
  };
  // needsFetching

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Paper header={'Persónuupplýsingar'}>
        <Grid container spacing={4}>
          <GridItem item md={6}>
            <Controller
              control={control}
              name='name'
              render={({ field: { ref, onBlur, ...rest } }) => (
                <TextField
                  label={'Nafn *'}
                  inputRef={ref}
                  onBlur={() => {
                    onBlur();
                    handleSaveFormState(rest.name, rest.value);
                  }}
                  disabled
                  {...rest}
                />
              )}
            />
          </GridItem>
          <GridItem item md={6}>
            <Controller
              control={control}
              name='ssn'
              render={({ field: { ref, onBlur, ...rest } }) => (
                <TextField
                  label={'Kennitala *'}
                  inputRef={ref}
                  inputProps={{ maxLength: 10 }}
                  onBlur={() => {
                    onBlur();
                    handleSaveFormState(rest.name, rest.value);
                  }}
                  disabled
                  {...rest}
                />
              )}
            />
          </GridItem>
          <GridItem item md={6}>
            <Controller
              control={control}
              name='phone'
              rules={{ required: true }}
              render={({
                field: { ref, onBlur, ...rest },
                fieldState: { error },
                formState,
              }) => {
                return (
                  <TextField
                    label={'Símanúmer *'}
                    inputRef={ref}
                    type='tel'
                    onBlur={() => {
                      onBlur();
                      handleSaveFormState(rest.name, rest.value);
                    }}
                    error={!!error}
                    helperText={
                      error?.type === 'required' &&
                      'Vantar að filla inn símanúmer'
                    }
                    {...rest}
                  />
                );
              }}
            />
          </GridItem>
          <GridItem item md={6}>
            <Controller
              control={control}
              name='email'
              render={({ field: { ref, onBlur, ...rest } }) => (
                <TextField
                  helperText='Staðfesta þarf netfang'
                  inputRef={ref}
                  label={'Netfang'}
                  onBlur={() => {
                    onBlur();
                    handleSaveFormState(rest.name, rest.value);
                  }}
                  disabled
                  {...rest}
                />
              )}
            />
          </GridItem>
          <GridItem item md={4}>
            <Controller
              control={control}
              name='bank'
              rules={{ required: true }}
              render={({
                field: { ref, onBlur, ...rest },
                fieldState: { error },
              }) => (
                <TextField
                  label={'Banki *'}
                  inputProps={{ maxLength: 6 }}
                  inputRef={ref}
                  error={!!error}
                  onBlur={() => {
                    onBlur();
                    handleSaveFormState(rest.name, rest.value);
                  }}
                  helperText={
                    error?.type === 'required' && 'Vantar að filla inn banka'
                  }
                  {...rest}
                />
              )}
            />
          </GridItem>
          <GridItem item md={4}>
            <Controller
              control={control}
              name='hb'
              rules={{ required: true }}
              render={({
                field: { ref, onBlur, ...rest },
                fieldState: { error },
              }) => (
                <TextField
                  label={'Höfuðbók *'}
                  inputRef={ref}
                  inputProps={{ maxLength: 2 }}
                  onBlur={() => {
                    onBlur();
                    handleSaveFormState(rest.name, rest.value);
                  }}
                  error={!!error}
                  helperText={
                    error?.type === 'required' &&
                    'Vantar að filla inn höfuðstól'
                  }
                  {...rest}
                />
              )}
            />
          </GridItem>
          <GridItem item md={4}>
            <Controller
              control={control}
              name='accountNumber'
              rules={{ required: true }}
              render={({
                field: { ref, onBlur, ...rest },
                fieldState: { error },
              }) => (
                <TextField
                  label={'Reikningsnúmer *'}
                  inputRef={ref}
                  inputProps={{ maxLength: 6 }}
                  onBlur={() => {
                    onBlur();
                    handleSaveFormState(rest.name, rest.value);
                  }}
                  error={!!error}
                  helperText={
                    error?.type === 'required' &&
                    'Vantar að filla inn reikningsnúmer'
                  }
                  {...rest}
                />
              )}
            />
          </GridItem>
        </Grid>
      </Paper>

      <FormNavigation
        onNext={handleSubmit(onSubmit)}
        stepStatus={{ current: 1, total: 9 }}
      />
    </form>
  );
};

const TextField = styled(TextFieldApp)`
  width: 100%;
`;

const GridItem = styled(Grid)`
  margin-top: 1rem;
`;
