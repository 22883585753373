import { ThemeProvider } from '@mui/material';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { muiTheme } from './config/mui';
import { LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";

import { QueryClient, QueryClientProvider } from 'react-query';
import { persistor, store } from './app/store';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { App } from './App';

// Sentry.init({
//   dsn: "https://edc5f10af01e40489c5aa5c919ce8d7d@o1007994.ingest.sentry.io/6066502",
//   integrations: [new Integrations.BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

async function baseQueryFn() {
  const id = localStorage.getItem('123');
  if (!id) {
    throw new Error('Notandi ekki til!');
  }

  try {
    const response = await fetch(
      `https://trauditmockbackend.azurewebsites.net/api/pension-application/${id}`
    );

    const json = await response.json();
    if (!response.ok || !json.jsonBlob) {
      throw new Error('Villa kom upp í vefþjónustu');
    }

    return JSON.parse(json.jsonBlob);
  } catch (error) {
    console.log(error);
  }
}

const queryClient = new QueryClient({
  defaultOptions: { queries: { queryFn: baseQueryFn } },
});

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <ThemeProvider theme={muiTheme}>
          <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <QueryClientProvider client={queryClient}>
                <App />
              </QueryClientProvider>
            </PersistGate>
          </ReduxProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root')
);
