import { Box, Step, StepConnector, StepLabel, Stepper, styled } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

export type Step = {
  type: 'Main'
  label: string
  parent?: never
} | {
  type: 'Sub'
  label: string
  parent: number
}

interface VerticalStepperProps {
  steps: Array<Step>
  activeStep: number
  onStepClick: (index: number) => void
}

export const VerticalStepper = ({ 
  steps, 
  activeStep,
  onStepClick,
}: VerticalStepperProps) => {
  const currentStep = steps[activeStep]
  const activeStepMain = currentStep?.type === 'Sub' ? currentStep.parent : activeStep

  return (
    <Stepper activeStep={activeStepMain} orientation="vertical" connector={null}>
      {steps.map((step, index) => (
        <Step key={step.label}>
          {index > 0 && step.type === 'Sub' && (
            <ShortConnector />
          )}
          {index > 0 && step.type === 'Main' && (
            <LongConnector />
          )}

          {step.type === 'Main' && 
            <CustomStepLabel onClick={() => step.label === 'Umsókn' ? onStepClick(index + 1) : onStepClick(index)} style={{cursor:'pointer'}}>
              {step.label}
            </CustomStepLabel>
          }

          {step.type === 'Sub' && 
            <CustomStepLabelSub
              onClick={() => onStepClick(index)} 
              style={{cursor:'pointer'}}
              StepIconProps={{
                icon: activeStep === index ? <CircleIconActive /> : activeStep > index ? <CircleIconDone /> : <CircleIconDisabled />}}>
              {step.label}
            </CustomStepLabelSub>
          }
        </Step>
      ))} 
    </Stepper>
  );
}

const CircleIconDisabled = styled(Box)`
  height:12px;
  width:12px;
  border-radius:50%;
  border: 2px solid #BDBDBD;
  margin-left: 7px;
`

const CircleIconActive = styled(Box)`
  height:12px;
  width:12px;
  border-radius:50%;
  border: 2px solid ${({ theme }) => theme.palette.secondary.main};
  margin-left: 7px;
`

const CircleIconDone = styled(Box)`
  height:12px;
  width:12px;
  border-radius:50%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  margin-left: 7px;
`

const LongConnector = styled(StepConnector)`
  height: 46px;
  margin-top: 9px;
  margin-bottom: 9px;

  > span{
    height: 46px;
  }
`

const ShortConnector = styled(StepConnector)`
  height: 16px;
  margin-top: 6px;
  margin-bottom: 6px;

  > span{
    height: 16px;
  }
`

const CustomStepLabel = styled(StepLabel)`
  .Mui-completed{
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight:700;
  }

  .MuiStepIcon-root.Mui-active{
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`

const CustomStepLabelSub = styled(StepLabel)`
  .Mui-completed {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight:700;

    &.MuiStepIcon-root{
      width:24px;
      height:12px;
    }
  }

  .MuiStepIcon-root.Mui-active{
    color: ${({ theme }) => theme.palette.primary.dark};
  }

`