import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import type { RootState } from '../store';

export const signinUserByNationalId = createAsyncThunk(
  'users/signin',
  async (nationalId: string) => {
    try {
      const response = await fetch(
        'https://trauditmockbackend.azurewebsites.net/api/authentication',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            SSN: nationalId,
            Password: '', // Password er ekki notað en þarf að vera fyrir API
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data.authenticated) {
          return data.ssn as string;
        } else {
          throw new Error('Notandi eða Lykilorð rangt!');
        }
      } else {
        throw new Error('Ekki næst samband við vefþjónustu...');
      }
    } catch (error) {
      console.log(error);
      throw new Error('Kerfisvilla í vefþjónustu...');
    }
  }
);

export interface User {
  nationalId: string;
  fullName: string;
  address: string;
  email: string;
  mobile: string;
  phone: string;
  bankAccount: string;
  spouse: FamilyMember[];
  children: FamilyMember[];
  // Hvar kemur "Réttindi erlendis og Ekki full greiðsla"?
}

interface FamilyMember {
  fullName: string;
  nationalId: string;
}

interface UsersState {
  loggedInUserId: string | null; // strengur er kennitala sem lyklar inná user í hardcodedData
  // Harðkóðuð gildi úr gagnasetti sem ekki er hægt að breyta. Eru bara geymd locally, ekki í vefþjónustu
  immutableData: {
    [key: string]: User;
  };
}

const initialState: UsersState = {
  loggedInUserId: null,
  immutableData: {
    '0101407789': {
      nationalId: '010140-7789',
      fullName: 'Hansína Ebenesardóttir',
      address: '',
      email: 'hansinae@gmail.com',
      mobile: '855-1111',
      phone: '565-1111',
      bankAccount: '0115-15-001111',
      spouse: [],
      children: [],
    },
    '0101502989': {
      nationalId: '010150-2989',
      fullName: 'Fimmsundtrýna Jafetsdóttir',
      address: '',
      email: '5sund@gmail.com',
      mobile: '855-2222',
      phone: '565-2222',
      bankAccount: '0115-15-001112',
      spouse: [],
      children: [
        {
          fullName: '',
          nationalId: '130102-2220',
        },
      ],
    },
  },
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    didSignin: (state, action: PayloadAction<string>) => {
      state.loggedInUserId = action.payload;
    },
    didSignout: (state) => {
      state.loggedInUserId = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signinUserByNationalId.fulfilled, (state, action) => {
      state.loggedInUserId = action.payload;
    });
  },
});

export const { didSignin, didSignout } = usersSlice.actions;

const selectSelf = (state: RootState) => state.users;
const selectLoggedInUserId = createSelector(
  selectSelf,
  (state) => state.loggedInUserId
);
const selectUsers = createSelector(selectSelf, (state) => state.immutableData);

// selectUser -> null | User
// Skilar innskráðum notanda ef notandi er innskráður annars null
export const selectUser = createSelector(
  selectLoggedInUserId,
  selectUsers,
  (id, users) => (id !== null ? users[id] : null)
);
