import { createTheme } from '@mui/material/styles'

// Configuration: mui
export const muiTheme = createTheme({
  typography: {
    fontFamily: [
      'DM Sans',
      'sans-serif',
    ].join(','),
    h1: {
      fontWeight: 400,
      letterSpacing: '-1.5px'
    },
    h2: {
      fontWeight: 400,
      letterSpacing: '-0.5px',
    },
    h3: {
      fontWeight: 500
    },
    h4: {
      fontWeight: 500,
      letterSpacing: '0.25px',
    },
    h5: {
      fontWeight: 700
    },
    h6: {
      fontWeight: 700,
      letterSpacing: '0.15px',
    },
    body1: {
      letterSpacing: '0.15px'
    },
    body2: {
      letterSpacing: '0.15px'
    },
    subtitle1: {
      letterSpacing: '0.15px'
    },
    subtitle2: {
      letterSpacing: '0.1px'
    }
  },
  palette: {
    primary: {
      main: '#006F51',
      dark: '#004027',
      light: '#4D9A85',
    },
    secondary: {
      main: '#FFD372',
      dark: '#FFB742',
      light: '#FFE09C',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    error: {
      main: '#F44336',
      dark: '#E31B0C',
      light: '#F88078',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    info: {
      main: '#2196F3',
      dark: '#0B79D0',
      light: '#64B6F7',
      contrastText: '#FFFFFF'
    },
    warning: {
      main: '#ED6C02',
      dark: '#C77700',
      light: '#FFB547',
      contrastText: '#FFFFFF'
    },
    success: {
      main: '#4CAF50',
      dark: '#3B873E',
      light: '#7BC67E',
      contrastText: '#FFFFFF'
    }
  },
  components: {
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          '.MuiBreadcrumbs-separator': {
            marginRight: '6px',
            marginLeft: '6px'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,

          // '&:hover': {
          //   backgroundColor: '#ffc74d'
          // },

          '&.MuiButton-sizeMedium': {
            padding: '10px 20px'
          },

          '&.MuiButton-sizeSmall': {
            padding: '8px 16px',
            letterSpacing: '0.46px'
          },

          '&.MuiButton-sizeLarge': {
            padding: '15px 26px',
            fontSize: '1rem',
            lineHeight: '1.625',
          }
        }
      }
    }
  }
})