import { ArrowBack } from '@mui/icons-material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from '@mui/material';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  flipUserConsent,
  selectPensionForm,
} from '../app/applications/applicationsSlice';
import { useAppSelector } from '../app/store';
import { Paper } from './../components/Paper';
import { FormButton } from '../components/FormButton';
import { FormNavigation } from '../components/FormNavigation';

type Inputs = {
  confirmation: boolean;
};
interface HandInStepProps {
  onSubmit?: (inputs: Inputs) => void;
  onBack?: () => void;
}
export const HandInStep = ({
  onSubmit: propOnSubmit,
  onBack,
}: HandInStepProps) => {
  const pensionForm = useAppSelector(selectPensionForm);
  const dispatch = useDispatch();

  const { handleSubmit, control, watch, getValues } = useForm<Inputs>({
    defaultValues: {
      confirmation: false,
    },
  });

  const handleSaveFormState = (userConsent: boolean) => {
    dispatch(flipUserConsent(userConsent));
  };

  const onSubmit: SubmitHandler<Inputs> = (data) => {

    propOnSubmit && propOnSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Paper header={'Skilmálar og senda'}>
        <p>
          Tryggingastofnun er heimilt að afla nauðsynlegra upplýsinga til að
          unnt sé að framfylgja þeim lögum sem stofnunin starfar eftir.
          Upplýsingar geta varðað bæði hagi umsækjanda og tekjur. Eftirtöldum
          stofnunum er skylt að veita upplýsingar til Tryggingastofnunar;
          skattyfirvöld, Vinnumálastofnun, Þjóðskrá Íslands, Innheimtustofnun
          sveitarfélaga, Fangelsismálastofnun, Útlendingastofnun,
          Ríkislögreglustjóri, Samgöngustofa, lífeyrissjóðir, sjúkrastofnanir,
          dvalar-og hjúkrunarheimili, sveitarfélög, sjúkratryggingastofnanir,
          Lánasjóður íslenskra námsmanna, viðurkenndar menntastofnanir innan
          hins almenna menntakerfis og skólar á háskólastigi. Ekki er aflað
          meiri upplýsinga en nauðsynlegt er hverju sinni.
        </p>

        <p>
          Umsækjanda er skylt að tilkynna um allar breytingar á aðstæðum sem
          geta haft áhrif á réttindi og greiðslur.
        </p>
        <p>
          Athygli er vakin á því að umsóknir sem eru sendar Tryggingastofnun
          rafrænt verða afgreiddar og þeim svarað rafrænt.
        </p>
        <StyledBox>
          <Controller
            control={control}
            name='confirmation'
            rules={{ required: true }}
            render={({
              field: { ref, onChange, ...rest },
              fieldState: { error },
            }) => (
              <FormControl error={!!error}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={pensionForm?.userConsent}
                        inputRef={ref}
                        onChange={(e) => {
                          onChange(e);
                          handleSaveFormState(e.target.value === 'false');
                        }}
                        {...rest}
                      />
                    }
                    label='Ég hef lesið skilmála Tryggingastofnunar og samþykki þá. *'
                  />
                  {error?.type === 'required' && (
                    <FormHelperText>
                      Vinsamlegast samþykktu skilmála áður en haldið er áfram
                    </FormHelperText>
                  )}
                </FormGroup>
              </FormControl>
            )}
          />
        </StyledBox>
      </Paper>

      <FormNavigation
        onNext={handleSubmit(onSubmit)}
        onBack={onBack}
        stepStatus={{ current: 8, total: 9 }}
      />
    </form>
  );
};

const StyledBox = styled(Box)`
  margin-top: 1rem;
`;
