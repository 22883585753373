import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Paper } from '../components/Paper/Paper';
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  IconButton,
} from '@mui/material';
import { TextField as TextFieldApp } from './../components/TextField';
import styled from 'styled-components';
import { RadioBox } from '../components/RadioBox';
import { FormButton } from '../components/FormButton';
import { Alert } from '../components/Alert';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Button } from '../components/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { ArrowBack } from '@mui/icons-material';
import { HomeInfoInputs } from '../app/applications/applicationsService';
import { FormNavigation } from '../components/FormNavigation';

type Inputs = {
  requestHousingSupplement: boolean;
  housingConditions: string;
  hasAdolescenceAtHome: boolean;
  adolescencesSSN: string;
};

interface HomeInfoStepProps {
  onSubmit?: (inputs: HomeInfoInputs) => void;
  onBack?: () => void;
  handleSaveDataOnBlur: (
    key: string,
    value: string | number | boolean | any[]
  ) => void;
  existingData: HomeInfoInputs;
}

export const HomeInfoStep = ({
  onSubmit: propOnSubmit,
  onBack,
  handleSaveDataOnBlur: handleSaveFormState,
  existingData,
}: HomeInfoStepProps) => {
  const { handleSubmit, control, watch, getValues } = useForm<Inputs>({
    defaultValues: {
      requestHousingSupplement: existingData.requestHousingSupplement,
      housingConditions: existingData.housingConditions,
      hasAdolescenceAtHome: existingData.hasAdolescenceAtHome,
      adolescencesSSN: '',
    },
  });

  const [requestHousingSupplement, hasAdolescenceAtHome] = watch([
    'requestHousingSupplement',
    'hasAdolescenceAtHome',
  ]);

  // úff...
  function shouldShowContent(cond: string | boolean) {
    return typeof cond === 'boolean' ? cond : cond === 'true';
  }

  const [adolescencesSSN, setAdolescencesSSN] = useState<string[]>([
    ...existingData.adolescencesSSN,
  ]);
  const handleAddAdolescencesSSN = () => {
    const adolescenceSSN = getValues('adolescencesSSN');
    const result = [...adolescencesSSN, adolescenceSSN];
    setAdolescencesSSN(result);
    handleSaveFormState('adolescencesSSN', [...result]);
  };
  const handleRemoveAdolescencesSSN = (index: number) => {
    const result = [
      ...adolescencesSSN.slice(0, index),
      ...adolescencesSSN.slice(index + 1),
    ];
    setAdolescencesSSN(result);
    handleSaveFormState('adolescencesSSN', [...result]);
  };

  const onSubmit: SubmitHandler<Inputs> = ({
    hasAdolescenceAtHome,
    housingConditions,
    requestHousingSupplement,
  }) => {
    

    const result: HomeInfoInputs = {
      adolescencesSSN,
      hasAdolescenceAtHome,
      housingConditions,
      requestHousingSupplement,
    };

    propOnSubmit && propOnSubmit(result);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Paper header={'Heimilisuppbót'}>
        <Typography variant='body1' component='p'>
          Heimilisuppbót er heimilt að greiða lífeyrisþega sem nýtur
          tekjutryggingar ef hann er einn um heimilirekstur, á maka sem dvelur á
          hjúkrunarheimili, býr með barni/börnum yngri en 18 ára, eða 18 - 25
          ára ungmenni/um sem eru í námi.
        </Typography>

        <Grid container spacing={4}>
          <GridItem item md={12}>
            <Typography component='p' variant='h6'>
              Sækja um heimilisuppbót?
            </Typography>

            <Controller
              control={control}
              name='requestHousingSupplement'
              render={({ field: { onChange, ...rest } }) => (
                <RadioBox
                  label=''
                  isRow
                  items={[
                    { label: 'Já', value: true },
                    { label: 'Nei', value: false },
                  ]}
                  radioGroup={{
                    onChange: (e) => {
                      onChange(e);
                      handleSaveFormState(rest.name, e.target.value === 'true');
                    },
                    ...rest,
                  }}
                />
              )}
            />
          </GridItem>

          {shouldShowContent(requestHousingSupplement) && (
            <>
              <GridItem item md={12}>
                <Typography component='h6' variant='h6'>
                  Húsnæðisaðstæður
                </Typography>

                <Controller
                  control={control}
                  name='housingConditions'
                  render={({ field: { onChange, ...rest } }) => (
                    <RadioBox
                      label=''
                      items={[
                        { label: 'Bý í eigin húsnæði', value: 'own' },
                        { label: 'Bý í leiguhúsnæði', value: 'rent' },
                      ]}
                      radioGroup={{
                        onChange: (e) => {
                          onChange(e);
                          handleSaveFormState(rest.name, e.target.value);
                        },
                        ...rest,
                      }}
                    />
                  )}
                />
              </GridItem>
              <GridItem item md={12}>
                <Alert severity='info' icon={false}>
                  <Typography component='p' variant='body2'>
                    Heimilisuppbót fellur niður
                  </Typography>
                  <List>
                    <ListItem>
                      <StyledListItemIcon>
                        <CancelRoundedIcon sx={{ color: 'error.light' }} />
                      </StyledListItemIcon>
                      <StyledListItemText primary='Þegar viðkomandi býr ekki lengur einn.' />
                    </ListItem>
                    <ListItem>
                      <StyledListItemIcon>
                        <CancelRoundedIcon sx={{ color: 'error.light' }} />
                      </StyledListItemIcon>
                      <StyledListItemText primary='Þegar tekjutrygging lífeyrisþefa fellur niður.' />
                    </ListItem>
                    <ListItem>
                      <StyledListItemIcon>
                        <CancelRoundedIcon sx={{ color: 'error.light' }} />
                      </StyledListItemIcon>
                      <StyledListItemText primary='Þegar viðkomandi flytur úr landi.' />
                    </ListItem>
                    <ListItem>
                      <StyledListItemIcon>
                        <CancelRoundedIcon sx={{ color: 'error.light' }} />
                      </StyledListItemIcon>
                      <StyledListItemText primary='Þegar viðkomandi skiptir um húsnæði.' />
                    </ListItem>
                  </List>
                </Alert>
              </GridItem>
              <GridItem item sm={12}>
                <Typography component='h6' variant='h6'>
                  Býr ungmenni á aldrinum 18-25 ára sem er í námi á heimilinu?
                </Typography>
                <Controller
                  control={control}
                  name='hasAdolescenceAtHome'
                  render={({ field: { onChange, ...rest } }) => (
                    <RadioBox
                      label=''
                      isRow
                      items={[
                        { label: 'Já', value: true },
                        { label: 'Nei', value: false },
                      ]}
                      radioGroup={{
                        onChange: (e) => {
                          onChange(e);
                          handleSaveFormState(
                            rest.name,
                            e.target.value === 'true'
                          );
                        },
                        ...rest,
                      }}
                    />
                  )}
                />
              </GridItem>

              {shouldShowContent(hasAdolescenceAtHome) && (
                <>
                  <LowerGridItem
                    item
                    md={12}
                    display='flex'
                    alignItems='center'
                  >
                    <Controller
                      control={control}
                      name='adolescencesSSN'
                      render={({ field: { ref, onBlur, ...rest } }) => (
                        <TextField
                          label={'Kennitala'}
                          style={{ marginRight: 32 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                    <LowerGridButton
                      onClick={handleAddAdolescencesSSN}
                      variant='outlined'
                      style={{ minWidth: 120, height: 56 }}
                    >
                      Bæta við
                    </LowerGridButton>
                  </LowerGridItem>

                  {!!adolescencesSSN.length && (
                    <>
                      <GridItem item md={12} display='flex' alignItems='center'>
                        <Typography
                          variant='body2'
                          component='p'
                          style={{ paddingLeft: 12 }}
                        >
                          Sótt er um barnalífeyri með:
                        </Typography>
                      </GridItem>

                      {/* TODO: This should be a reusable component. See FormLineEntry. */}
                      <Box
                        style={{
                          display: 'flex',
                          width: '100%',
                          paddingLeft: 32,
                          flexWrap: 'wrap',
                        }}
                      >
                        {adolescencesSSN.map((item, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              height: '40px',
                              backgroundColor: '#FAFAFA',
                              border: '1px solid rgba(0, 0, 0, 0.15)',
                              width: '100%',
                              '&:not(:first-child)': {
                                borderTop: 0,
                              },
                            }}
                          >
                            <Typography
                              style={{ width: '100%', paddingLeft: 12 }}
                              variant='body2'
                              component='div'
                              color='text.secondary'
                            >
                              {item}
                            </Typography>

                            <IconButton
                              onClick={() => handleRemoveAdolescencesSSN(index)}
                              sx={{
                                height: '100%',
                                borderRadius: 0,
                                border: '1px solid rgba(0, 0, 0, 0.15)',
                                borderWidth: '0 0 0 1px',
                              }}
                            >
                              <DeleteIcon
                                style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                              />
                            </IconButton>
                          </Box>
                        ))}
                      </Box>
                    </>
                  )}

                  <GridItem item md={12}>
                    <Alert severity='info'>
                      Vottorð um skólavist verður að fylgja umsókn, ef þú telur
                      pað ekki vera til staðar hjá TR.
                    </Alert>
                  </GridItem>
                </>
              )}
            </>
          )}
        </Grid>
      </Paper>

      <FormNavigation
        onNext={handleSubmit(onSubmit)}
        onBack={onBack}
        stepStatus={{ current: 3, total: 9 }}
      />
    </form>
  );
};

const StyledListItemText = styled(ListItemText)`
  > span {
    font-size: 14px;
  }
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: auto;
  margin-right: 10px;
`;

const GridItem = styled(Grid)`
  margin-top: 1rem;
`;

const TextField = styled(TextFieldApp)`
  width: 100%;
`;

const LowerGridItem = styled(GridItem)`
  display: flex;
  width: 120;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const LowerGridButton = styled(Button)`
  display: flex;
  width: 120;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 8px;
    justify-content: flex-start;
  }
` as typeof Button;
