import {
  HorizontalStepper,
  HorizontalStepProps,
} from '../../components/HorizontalStepper';
import { Paper } from '../../components/Paper';
import { Alert } from '../../components/Alert';
import AddIcon from '@mui/icons-material/Add';
import { useAppDispatch, useAppSelector } from '../../app/store';
import {
  didClearApplications,
  didStartNewApplication,
  selectApplication,
} from '../../app/applications/applicationsSlice';
import { ApplicationType } from '../../app/applications/applicationsService';
import { useNavigate } from 'react-router-dom';
import { selectUser } from '../../app/users/usersSlice';
import { Link } from 'react-router-dom';
import {
  AlertTitle,
  Container,
  Button,
  Grid,
  Box,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import styled from 'styled-components';
import { FrontpageBox } from '../../components/FrontpageBox';
import { PageTitle } from '../../components/PageTitle';
import { SVGApplication } from '../../components/svg/SVGApplication';
import { SVGDebt } from '../../components/svg/SVGDebt';
import { SVGDocument } from '../../components/svg/SVGDocument';
import { SVGPlan } from '../../components/svg/SVGPlan';
import { SVGSchedule } from '../../components/svg/SVGSchedule';

const steps: Array<HorizontalStepProps> = [
  { number: 0, label: 'Nýskráð', subText: '', hasError: false },
  { number: 1, label: 'Fylgiskjöl', subText: 'Gögnum skilað', hasError: false },
  {
    number: 2,
    label: 'Úrvinnsla gagna',
    subText: 'Beðið eftir gögnum',
    hasError: false,
  },
  { number: 3, label: 'Úrskurður', subText: '', hasError: false },
  { number: 4, label: 'Niðurstöður', subText: '', hasError: false },
];

const OpenApplicationAlert = () => {
  return (
    <CustomAlert
      severity='info'
      action={
        <AlertButtonContainer>
          <AlertButton
            variant='outlined'
            color='inherit'
            size='small'
            href='umsoknir/ellilifeyri'
          >
            Opna umsókn
          </AlertButton>
        </AlertButtonContainer>
      }
    >
      <AlertTitle>Þú ert með ókláraða umsókn</AlertTitle>
      Smelltu á takkann til að fara inní umsóknina
    </CustomAlert>
  );
};

// const MissingSalaryPlanAlert = () => {
//   return(
//     <CustomAlert
//       severity='warning'
//       style={{display: 'flex', alignItems: 'center'}}
//       action={
//         <Button variant='outlined' color='inherit' size='small'>
//           Skila inn tekjuáætlun
//         </Button>
//       }
//     >
//       <AlertTitle>Tekjuáætlun vantar</AlertTitle>
//       Til þess að klára umsókn þarftu að skila inn tekjuáætlun
//     </CustomAlert>
//   )
// }

export const Home = () => {
  const [activeStep, setActiveStep] = useState(steps[0]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const application = useAppSelector(selectApplication);

  const startNewApplicationHandler = () => {
    dispatch(
      didStartNewApplication({
        type: ApplicationType.Ellilíferyrir,
        user: user!,
      })
    );

    navigate('/umsoknir/ellilifeyri');
  };

  const clearApplicationsHandler = () => {
    dispatch(didClearApplications());
  };

  return (
    <Container>
      <PageTitle
        heading={`Velkomin ${user?.fullName}`}
        subtitle='á öruggt svæði Tryggingastofnunar'
      />

      <HeaderWithButton>
        <PageSubtitle component='h2' variant='h6'>
          Staða umsókna
        </PageSubtitle>
        {/* <Button
          color='primary'
          variant='contained'
          endIcon={<AddIcon />}
          onClick={clearApplicationsHandler}
        >
          hreinsa
        </Button> */}
        <Button
          color='primary'
          variant='contained'
          endIcon={<AddIcon />}
          onClick={startNewApplicationHandler}
          disabled={application !== null}
        >
          NÝ UMSÓKN
        </Button>
      </HeaderWithButton>
      <Paper
        noAnimation={true}
        header='Ellilífeyrir'
        style={{ marginBottom: 75 }}
      >
        <HorizontalStepperMain activeStep={activeStep} steps={steps} />
        {activeStep.number === 0 && <OpenApplicationAlert />}
      </Paper>
      <FlexGrid>
        <PageSubtitle component='h6' variant='h6' style={{ marginBottom: 35 }}>
          Flýtileiðir
        </PageSubtitle>
      </FlexGrid>
      <StyledGridItem
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <FlexGrid style={{ flex: 1 }} item>
          <FrontpageBox
            icon={<SVGApplication />}
            label='Umsóknir'
            href='/umsoknir/ellilifeyri'
          />
        </FlexGrid>
        <FlexGrid item>
          <FrontpageBox
            icon={<SVGDocument />}
            label='Rafræn skjöl'
            href='/mytr/files'
          />
        </FlexGrid>
        <FlexGrid item>
          <FrontpageBox
            icon={<SVGSchedule />}
            label='Greiðsluáætlun'
            href='/mytr/paymentplan'
          />
        </FlexGrid>
        <FlexGrid item>
          <FrontpageBox icon={<SVGPlan />} label='Tekjuáætlun' href='/' />
        </FlexGrid>
        <FlexGrid item>
          <FrontpageBox
            icon={<SVGDebt />}
            label='Skuldir og samningar'
            href='/mytr/deptsandcontracts'
          />
        </FlexGrid>
      </StyledGridItem>
    </Container>
  );
};
const StyledGridItem = styled(Grid)`
  ${(props) => props.theme.breakpoints.down('md')} {
    justify-content: center;
  }
` as typeof Grid;
const HeaderWithButton = styled(Box)`
  width: 100%;
  margin-bottom: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CustomAlert = styled(Alert)`
  margin-top: 40px;
  display: 'flex';
  align-items: 'center';
  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
    align-items: center;
    text-align: center;
    .MuiPaper-root {
      > .MuiAlert-action {
        margin: auto;
      }
    }
  }
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    ${({ theme }) => theme.palette.warning.main};
`;

const PageSubtitle = styled(Typography)`` as typeof Typography;

const AlertButton = styled(Button)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    margin: auto !important;
    .Mui-completed {
      color: ${({ theme }) => theme.palette.primary.main};
      font-weight: 700;

      &.MuiStepIcon-root {
        width: 24px;
        height: 12px;
      }
    }

    .MuiStepIcon-root.Mui-active {
      color: ${({ theme }) => theme.palette.primary.dark};
    }
  }
`;

const AlertButtonContainer = styled(Button)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
    justify-content: center;
    margin: auto;
    display: flex;
  }
`;

const HorizontalStepperMain = styled(HorizontalStepper)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0px;
    .MuiStepper-root {
      padding: 0px;
      > .MuiStep-root {
        padding: 0px !important;
      }
    }
  }
`;
const FlexGrid = styled(Grid)`
  flex: 1;
`;
