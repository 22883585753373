import { List, ListItemButton, ListItemText, Stack, styled } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'
import { Link, useMatch } from 'react-router-dom'
import cn from 'classnames'

interface SideMenuItemProps {
  href: string
}
const SideMenuItem: React.FC<SideMenuItemProps> = ({ href, children }) => {
  const match = useMatch(href)
  return (
    <ListItemButton
      sx={{ height: 52 }}
      component={Link}
      to={href}
      className={cn('item',{ 'active': !!match })}
    >
      {children}
    </ListItemButton>
  )
}
export interface SideMenuItem{
  href: string
  label: string
}
interface SideMenuProps {
  menu: Array<SideMenuItem>
}
export const SideMenu = ({ menu }:SideMenuProps) => {
  return(
    <StyledList>
      {menu.map(item => {
        return(
          <SideMenuItem
            key={item.label}
            href={item.href}
          >
            <ListItemText className="item-text" primary={item.label} />
          </SideMenuItem>
        )
      })}
    </StyledList>
  )
}

const StyledList = styled(Stack)`
  background-color:white;

  .item {
    .MuiTypography-root {
      color: rgba(0, 0, 0, 0.42);
      font-weight: bold;
    }

    &.active {
      background: #EEEEEE;
      .MuiTypography-root {
        color: ${({ theme }) => theme.palette.primary.main};
      }
    }
  }

  ${props => props.theme.breakpoints.down('lg')} {
    margin-bottom: 32px;
    flex-direction: row;
    
    .item {
      height: 50px;

      .MuiTypography-root {
        font-weight: 400;
        font-size: 0.75rem;
        text-align: center;
      }
    }
  }
`
