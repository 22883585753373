import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { User } from '../users/usersSlice';
import {
  Application,
  ApplicationForm,
  ApplicationsService,
  ApplicationType,
} from './applicationsService';

type UpdatePensionFormDataPayload = {
  step: number;
  key: string;
  value: string | number | boolean | any[];
};

const _saveApplicationRemotely = async (
  userNationalId: string,
  activeApplication: any
) => {
  try {
    const response = await fetch(
      `https://trauditmockbackend.azurewebsites.net/api/pension-application/${userNationalId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          JsonData: JSON.stringify(activeApplication),
        }),
      }
    );

    if (!response.ok) {
      throw new Error('Ekki tókst að vista upplýsingar!');
    }

    const json = await response.json();
    console.log(JSON.parse(json.jsonBlob));
  } catch (error) {
    console.log(error);
    throw new Error('Kerfisvilla í vefþjónustu...');
  }
};

export const updatePensionFormData = createAsyncThunk(
  'applications/updatePensionFormData',
  async (payload: UpdatePensionFormDataPayload, { getState, dispatch }) => {
    const userNationalId = (getState() as RootState).users.loggedInUserId;
    dispatch(didUpdatePensionFormData(payload));
    await _saveApplicationRemotely(
      userNationalId!,
      (getState() as RootState).applications.active
    );
  }
);

export const updatePensionFormMeta = createAsyncThunk(
  'applications/updatePensionFormMeta',
  async (
    payload: Partial<Omit<ApplicationForm, 'steps'>>,
    { getState, dispatch }
  ) => {
    const userNationalId = (getState() as RootState).users.loggedInUserId;
    dispatch(didUpdatePensionFormMeta(payload));
    await _saveApplicationRemotely(
      userNationalId!,
      (getState() as RootState).applications.active
    );
  }
);

export const save = createAsyncThunk(
  'applications/updatePensionFormData',
  async (payload: UpdatePensionFormDataPayload, { getState, dispatch }) => {
    const userNationalId = (getState() as RootState).users.loggedInUserId;
    dispatch(didUpdatePensionFormData(payload));
    await _saveApplicationRemotely(
      userNationalId!,
      (getState() as RootState).applications.active
    );
  }
);

export const fetchApplicationData = createAsyncThunk(
  'applications/fetchApplicationData',
  async (unknown, { getState }) => {
    try {
      const state = getState() as RootState;
      const userNationalId = state.users.loggedInUserId;

      const response = await fetch(
        `https://trauditmockbackend.azurewebsites.net/api/pension-application/${userNationalId}`
      );

      const json = await response.json();
      if (!response.ok || !json.jsonBlob) {
        throw new Error('Ekki tókst að sækja gögn');
      }

      return JSON.parse(json.jsonBlob);
    } catch (error) {
      console.log(error);
      throw new Error('Kerfisvilla í vefþjónustu...');
    }
  }
);

interface ApplicationsState {
  active: Application | null;
}

const initialState: ApplicationsState = {
  active: null,
};

export const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    didStartNewApplication: (
      state,
      action: PayloadAction<{ type: ApplicationType; user: User }>
    ) => {
      state.active = ApplicationsService.createNewApplicationOfType(
        action.payload.type,
        action.payload.user
      );
    },
    didUpdatePensionFormData(
      state,
      action: PayloadAction<UpdatePensionFormDataPayload>
    ) {
      const { step, key, value } = action.payload;
      const form = state.active?.stages[0].form;
      if (!form) {
        console.log('Big time villa: form er ekki til!!!');
      } else {
        const { data } = form.steps[step];
        form.steps[step].data = {
          ...data,
          [key]: value,
        };
      }
    },
    didUpdatePensionFormMeta(
      state,
      action: PayloadAction<Partial<Omit<ApplicationForm, 'steps'>>>
    ) {
      const form = state.active?.stages[0].form;
      if (!form) {
        console.log('Big time villa: form er ekki til!!!');
      } else {
        state.active!.stages[0].form = {
          ...form,
          ...action.payload,
        };
      }
    },
    flipUserConsent(state, action: PayloadAction<boolean>) {
      const form = state.active?.stages[0].form;
      if (!form) {
        console.log('Big time villa: form er ekki til!!!');
      } else {
        form.userConsent = action.payload;
      }
    },
    didClearApplications: (state) => {
      state.active = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchApplicationData.fulfilled, (state, action) => {
      state.active = action.payload;
    });
  },
});

export const {
  didStartNewApplication,
  didClearApplications,
  didUpdatePensionFormData,
  didUpdatePensionFormMeta,
  flipUserConsent,
} = applicationsSlice.actions;

const selectSelf = (state: RootState) => state.applications;

export const selectApplication = createSelector(
  selectSelf,
  (state) => state.active
);

export const selectPensionForm = createSelector(
  selectApplication,
  (application) => application?.stages[0].form
);
