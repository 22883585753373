import { Box, styled } from '@mui/material';
import { useState } from 'react';
import { Container } from '../components/Containers';
import { PageTitle } from '../components/PageTitle';
import { Step, VerticalStepper } from '../components/VerticalStepper';
import { FileUploadStep } from './FileUploadStep';
import { ApplicationOverview } from './ApplicationOverview';
import { FormPersonalInfo } from './FormPersonalInfo';
import { HandInStep } from './HandInStep';
import { HomeInfoStep } from './HomeInfoStep';
import { ParentPayment } from './ParentPayment';
import { SalaryStep2 } from './SalaryStep2';
import { PrelimCalc } from './PrelimCalc';
import { FinalOverview } from './FinalOverview';
import {
  selectPensionForm,
  updatePensionFormData,
  updatePensionFormMeta,
} from '../app/applications/applicationsSlice';
import { useAppDispatch, useAppSelector } from '../app/store';
import { Crumb, Breadcrumbs } from '../components/Breadcrumbs';

const CRUMBS: Crumb[] = [
  { label: 'Forsíða', href: '/' },
  { label: 'Umsóknir' },
  { label: 'Umsókn um ellilífeyri', active: true },
];

const LayoutForm = styled(Box)`
  display: flex;

  .form {
    flex-grow: 1;
    margin-right: 24px;
    ${({ theme }) => theme.breakpoints.down('sm')} {
      margin-right: 0px;
    }
  }
  .stepper {
    ${({ theme }) => theme.breakpoints.down('lg')} {
      display: none;
    }
  }
`;

const steps: Array<Step> = [
  { label: 'Persónuupplýsingar', type: 'Main' },
  { label: 'Umsókn', type: 'Main' }, // TODO: JUMP
  { label: 'Upplýsingar', type: 'Sub', parent: 1 },
  { label: 'Heimilisuppbót', type: 'Sub', parent: 1 },
  // { label: 'Barnalífeyrir vegna ellilífeyri', type: 'Sub', parent: 1 },
  // { label: 'Uppbót á lífeyri', type: 'Sub', parent: 1 },
  { label: 'Fylgiskjöl', type: 'Main' },
  { label: 'Tekjuáætlun', type: 'Main' },
  { label: 'Yfirferð', type: 'Main' },
  { label: 'Bráðabirgðaútreikningar', type: 'Main' },

  { label: 'Skilmálar og senda', type: 'Main' },
  { label: 'Umsókn send', type: 'Main' },
];

export const Ellilifeyri = () => {
  const dispatch = useAppDispatch();
  const pensionForm = useAppSelector(selectPensionForm);
  const [activeStepIndex, _setActiveStepIndex] = useState(
    pensionForm?.currentStep || 0
  );

  if (!pensionForm) {
    // Vantar loading
    return null;
  }

  // Ræna setActiveStepIndex til að save-a state
  const setActiveStepIndex: typeof _setActiveStepIndex = (activeIndex) => {
    _setActiveStepIndex(activeIndex);
    dispatch(updatePensionFormMeta({ currentStep: activeIndex as number }));
  };

  // We need to handle when we go one over
  const activeStep =
    activeStepIndex < steps.length
      ? steps[activeStepIndex]
      : steps[steps.length - 1];
  const handleBack = () => {
    const nextIndex = activeStepIndex - 1;

    if (nextIndex === 1) {
      setActiveStepIndex(0);
    } else setActiveStepIndex(nextIndex);

    if (!steps[nextIndex]) {
      // TODO: handle finished
    }
  };

  const handleNext = () => {
    const nextIndex = activeStepIndex + 1;
    setActiveStepIndex(nextIndex);
    // Time Constraints make me do this.
    if (nextIndex === 1) {
      setActiveStepIndex(2);
    }
    // Normal increment
    else {
      setActiveStepIndex(nextIndex);
    }

    if (!steps[nextIndex]) {
      // TODO: handle finished
    }
  };

  const handleSaveDataOnBlur = (
    key: string,
    value: string | number | boolean | any[]
  ) => {
    dispatch(updatePensionFormData({ step: activeStepIndex, key, value }));
  };

  const handleSubmit = () => {
    // TODO SUBMIT

    handleNext();
  };

  const handleClickStep = (index: number) => {
    if (index >= activeStepIndex) {
      return;
    }
    setActiveStepIndex(index);
  };

  return (
    <>
      <Breadcrumbs crumbs={CRUMBS} />
      <Container>
        <PageTitle heading='Umsókn um ellilífeyri' />

        <LayoutForm>
          <div className='form'>
            {activeStep.label === 'Persónuupplýsingar' && (
              <FormPersonalInfo
                onSubmit={handleNext}
                handleSaveDataOnBlur={handleSaveDataOnBlur}
                existingData={{ ...pensionForm.steps[0].data }}
              />
            )}
            {activeStep.label === 'Upplýsingar' && (
              <ParentPayment
                onSubmit={handleNext}
                onBack={handleBack}
                handleSaveDataOnBlur={handleSaveDataOnBlur}
                existingData={{ ...pensionForm.steps[2].data }}
              />
            )}
            {activeStep.label === 'Heimilisuppbót' && (
              <HomeInfoStep
                onSubmit={handleNext}
                onBack={handleBack}
                handleSaveDataOnBlur={handleSaveDataOnBlur}
                existingData={{ ...pensionForm.steps[3].data }}
              />
            )}
            {activeStep.label === 'Fylgiskjöl' && (
              <FileUploadStep onSubmit={handleNext} onBack={handleBack} />
            )}
            {activeStep.label === 'Tekjuáætlun' && (
              <SalaryStep2
                onSubmit={handleNext}
                onBack={handleBack}
                handleSaveDataOnBlur={handleSaveDataOnBlur}
                existingData={{ ...pensionForm.steps[5].data }}
              />
            )}
            {activeStep.label === 'Yfirferð' && (
              <ApplicationOverview onSubmit={handleNext} onBack={handleBack} />
            )}
            {activeStep.label === 'Bráðabirgðaútreikningar' && (
              <PrelimCalc onSubmit={handleNext} onBack={handleBack} />
            )}

            {activeStep.label === 'Skilmálar og senda' && (
              <HandInStep onSubmit={handleSubmit} onBack={handleBack} />
            )}
            {activeStep.label === 'Umsókn send' && <FinalOverview />}
          </div>

          <div className='stepper'>
            <VerticalStepper
              activeStep={activeStepIndex}
              onStepClick={handleClickStep}
              steps={steps}
            />
          </div>
        </LayoutForm>
      </Container>
    </>
  );
};
