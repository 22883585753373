import { Box, styled } from '@mui/material'

export const Container = styled(Box)`
  max-width: 1156px;
  width: 100%;
  margin: 0 auto;

  padding: 0 ${props => props.theme.spacing(2)};


  ${props => props.theme.breakpoints.up('sm')} {
    padding: 0 ${props => props.theme.spacing(6)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    padding: 0 50px;
  }
`