import { styled, Breadcrumbs as MuiBreadcrumbs, Link as MuiLink, Typography, Box, useTheme, useMediaQuery } from '@mui/material'
import { Link } from 'react-router-dom'
import { SVGBreadcrumbSeparator } from '../svg/SVGBreadcrumbSeparator'

const Container = styled(Box)`
  padding: 21px 24px 18px 24px;
  width: 100%;
  background-color: ${props => props.theme.palette.grey[100]};
`

export interface Crumb {
  label: string
  href?: string
  active?: boolean
}

interface BreadcrumbsProps {
  crumbs: Array<Crumb>
}
export const Breadcrumbs = ({
  crumbs,
}: BreadcrumbsProps) => {
  const theme = useTheme()
  const aboveLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true })

  let maxItems = 2
  if(aboveLG) {
    maxItems = 3
  }

  return (
    <Container>
      <MuiBreadcrumbs 
        maxItems={maxItems}
        separator={<SVGBreadcrumbSeparator />} aria-label="breadcrumb"
      >
        {crumbs.map(crumb => {
          if(crumb.active) {
            return (
              <Typography key={crumb.label} variant='body1' color="text.primary" component="span">{crumb.label}</Typography>
            )
          }
          if(crumb.href) {
            return (
              <MuiLink
                key={crumb.label}
                component={Link}
                underline="always" 
                to={crumb.href}
              >
                <Typography variant='body1' color="text.secondary" component="span">{crumb.label}</Typography>
              </MuiLink>
            )
          }
          return (
            <Typography key={crumb.label} variant='body1' color="text.secondary" component="span">{crumb.label}</Typography>
          )
        })}
      </MuiBreadcrumbs>
    </Container>
  )
}
