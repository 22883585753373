import { Box, styled, Typography } from '@mui/material'
import React from 'react'

const Container = styled(Box)`
  width: 100%;
  padding: 41px 0 47px 0;
`

type PageTitleProps = {
  heading?: string
  subtitle?: string
}
export const PageTitle: React.FC<PageTitleProps> = ({
  heading,
  subtitle,
  children,
}) => (
  <Container style={{ 
    padding: subtitle ? '41px 0 47px 0' : '56px 0 32px 0'
  }}>
    <Typography 
      color={subtitle ? 'primary' : undefined}
      variant='h4'
      component="h1"
    >
      {children ? children : heading}
    </Typography>
    {subtitle && <Typography variant='subtitle1' component="p" style={{ marginBottom: '5px' }}>{subtitle}</Typography>}
  </Container>
)