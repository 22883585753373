import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../app/store';
import { signinUserByNationalId } from '../app/users/usersSlice';
import { fetchApplicationData } from '../app/applications/applicationsSlice';
import { Box, styled, Stack } from '@mui/material';
import { TextField } from '../components/TextField';
import { Button } from '../components/Button';
import React from 'react';

type SigninInputs = {
  kennitala: string;
  password: string;
};

export const Signin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [errorMsg, setErrorr] = React.useState('');
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SigninInputs>();

  //@ts-ignore
  const from = location.state?.from?.pathname || '/';
  const onSubmit: SubmitHandler<SigninInputs> = async (data) => {
    try {
      // unwrappa til að force-a success / failure
      await dispatch(signinUserByNationalId(data.kennitala)).unwrap();
      await dispatch(fetchApplicationData());
      navigate(from, { replace: true });
    } catch (error) {
      setErrorr('Innskráning gekk ekki');
    }
  };

  return (
    <ContainerBox>
      <StyledBox boxShadow={3}>
        <h2>Innskráning</h2>
        <Stack spacing={2} component='form' onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name='kennitala'
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <TextField
                fullWidth
                id='kennitala'
                label='kennitala'
                variant='outlined'
                inputRef={ref}
                error={!!error}
                helperText={error?.type === 'required' && 'Kennitala vantar'}
                {...rest}
              />
            )}
            rules={{ required: true }}
          />
          {/* {errors.kennitala && errors.kennitala.type === 'required' && (
            <span>This field is required</span>
          )}
          {errors.kennitala && errors.kennitala.type === 'value' && (
            <span>{errors.kennitala.message}</span>
          )} */}

          <Controller
            control={control}
            name='password'
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <TextField
                inputRef={ref}
                type='password'
                fullWidth
                id='password'
                label='Password'
                variant='outlined'
                error={!!error}
                helperText={error?.type === 'required' && 'Lykilorð vantar'}
                {...rest}
              />
            )}
            rules={{ required: true }}
          />
          {/* {errors.password && errors.password.type === 'required' && (
            <span>This password field is required</span>
          )} */}
          {/* {errors.password && errors.password.type === 'value' && (
            <span>{errors.password.message}</span>
          )} */}
          {errorMsg}
          <Button type='submit' variant='outlined'>
            Submit
          </Button>
        </Stack>
      </StyledBox>
    </ContainerBox>
  );
};
const StyledBox = styled(Box)`
  width: 20vw;
  min-width: 327px;
  vertical-align: middle;
  padding: 25px;
  border-radius: 8px;
` as typeof Box;

const ContainerBox = styled(Box)`
  display: flex;
  justify-content: center;
  padding: 25px;
  margin-top: 25px;
`;
const ImageBox = styled(Box)`
  background-image: url('/assets/mainpageimage.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 52vw;
  height: 92vh;
  bottom: 0;
  position: absolute;
  right: 0;
  z-index: 2;
  padding: 25px;
`;
