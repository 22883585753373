import { Stepper, Step, StepLabel, styled, Typography } from '@mui/material'

export interface HorizontalStepProps{
  number: number
  label: string
  subText: string
  hasError: boolean
}

export interface ActiveStepProps{
  number: number
  hasError: boolean
  infoBox?: React.ReactNode
}

interface HorizontalStepperProps {
  steps: Array<HorizontalStepProps>
  activeStep: HorizontalStepProps
}

export const HorizontalStepper = ({ steps, activeStep }: HorizontalStepperProps) => {
  return (
    <Stepper activeStep={activeStep.number}>
      {steps.map((step, index) => {
        const labelProps: {
          optional?: React.ReactNode;
          error?: boolean;
        } = {};
        if (activeStep.hasError && index === activeStep.number) {
          labelProps.error = true;
          labelProps.optional = (
            <Typography variant="caption" color="warning.main">
              {step.subText}
            </Typography>
          );
        }else if(index === activeStep.number && step.subText !== ''){
          labelProps.optional = (
            <Typography variant="caption">
              {step.subText}
            </Typography>
          );
        }
        return(
          <Step key={step.label}>
            <CustomStepLabelSub {...labelProps}>{step.label}</CustomStepLabelSub>
          </Step>
        )
      })} 
    </Stepper>
  );
}

const CustomStepLabelSub = styled(StepLabel)`
  .Mui-completed{
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight:500;
  }

  .Mui-error{
    color: ${({ theme }) => theme.palette.warning.main};
  }

  .Mui-active{
    font-weight:700;
  }

  .MuiStepIcon-root.Mui-active{
    color: ${({ theme }) => theme.palette.secondary.main};

    &.Mui-error{
      color: ${({ theme }) => theme.palette.warning.main};
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')}{
    .MuiStepLabel-labelContainer{
      .MuiStepLabel-label {
        display:none;

        &.Mui-active{
          display:block
        }
      }
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')}{
    .MuiStepLabel-labelContainer{
      .MuiStepLabel-label {
        display:none !important;
      }
    }
  }
`

