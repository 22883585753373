import { User } from '../users/usersSlice';

enum ApplicationStageLabel {
  Nýskráð = 'Nýskráð',
  Fylgiskjöl = 'Fylgiskjöl',
  Tekjuáætlun = 'Tekjuáætlun',
  ÚrvinnslaGagna = 'Úrvinnsla gagna',
  Úrskurður = 'Úrskurður',
  Niðurstöður = 'Niðurstöður',
}

interface ApplicationStagePrompt {
  title: string;
  message: string;
  buttonText: string;
  buttonRoute: string;
}

export interface ApplicationStage {
  label: ApplicationStageLabel;
  order: number;
  status: 'ready' | 'error' | 'done';
  prompt?: ApplicationStagePrompt;
  subText?: string;
  form?: ApplicationForm;
}

export interface FormPersonalInfoInputs {
  name: string;
  ssn: string;
  phone: string;
  email: string;
  bank: string;
  hb: string;
  accountNumber: string;
}

export interface ParentPaymentInputs {
  hasCheckedPensionFunds: boolean;
  requestEarlyPension: boolean;
  hasLivedAndWorkedAbroad: boolean;
  abroadEntries: {
    country: string;
    dateFrom: string;
    dateTo: string;
  }[];
}

export interface HomeInfoInputs {
  hasAdolescenceAtHome: boolean;
  adolescencesSSN: string[];
  requestHousingSupplement: boolean;
  housingConditions: string;
}

export interface SalaryStep2Inputs {
  salary: {
    category: string;
    type: string;
    yearly: string;
    monthly: string;
    currency: string;
  }[];
  comment: string;
}

interface Step4Data {
  didRequestChildPension: boolean; // :D

  // ef didRequestChildPension === true
  childPensionNationalIds: string[];
}

interface Step5Data {
  didApplyForExtraBenefits: boolean;

  // ef didApplyForExtraBenefits === true
  reasonsForExtraBenefits: {
    key: string;
    selected: boolean;
  }[];

  benefitsStartDate: string;
}

type StepData =
  | FormPersonalInfoInputs
  | ParentPaymentInputs
  | HomeInfoInputs
  | SalaryStep2Inputs
  | Step4Data
  | Step5Data;

interface FormStep<T extends StepData> {
  id: number;
  data: T;
}

export interface ApplicationForm {
  userConsent: boolean;
  complete: boolean;
  steps: [
    FormStep<FormPersonalInfoInputs>,
    FormStep<FormPersonalInfoInputs>,
    FormStep<ParentPaymentInputs>,
    FormStep<HomeInfoInputs>,
    FormStep<HomeInfoInputs>,
    FormStep<SalaryStep2Inputs>,
    FormStep<Step4Data>,
    FormStep<Step5Data>
  ];
  currentStep: number;
}

export enum ApplicationType {
  Ellilíferyrir = 'Ellilíferyrir',
}

export interface Application {
  type: ApplicationType;
  stages: ApplicationStage[];
  currentStage: number;
}

export const ApplicationsService = {
  createNewApplicationOfType(
    applicationType: ApplicationType,
    user: User
  ): Application {
    switch (applicationType) {
      case ApplicationType.Ellilíferyrir:
      default:
        return this.createNewEllilíferyrirApplication(user);
    }
  },
  createNewEllilíferyrirApplication(user: User): Application {
    const [bank, hb, accountNumber] = user.bankAccount.split('-');
    const steps: ApplicationForm['steps'] = [
      {
        id: 0,
        data: {
          email: user.email,
          phone: user.mobile,
          name: user.fullName,
          ssn: user.nationalId,
          bank,
          hb,
          accountNumber,
        },
      },
      {
        // guard object, vibbó hack útaf tímaskorti fyrir formið
        id: 500,
        data: {
          email: user.email,
          phone: user.mobile,
          name: user.fullName,
          ssn: user.nationalId,
          bank,
          hb,
          accountNumber,
        },
      },
      {
        id: 1,
        data: {
          hasCheckedPensionFunds: false,
          hasLivedAndWorkedAbroad: false,
          requestEarlyPension: false,
          abroadEntries: [],
        },
      },
      {
        id: 2,
        data: {
          hasAdolescenceAtHome: false,
          adolescencesSSN: [],
          requestHousingSupplement: false,
          housingConditions: 'own',
        },
      },
      {
        id: 2,
        data: {
          hasAdolescenceAtHome: false,
          adolescencesSSN: [],
          requestHousingSupplement: false,
          housingConditions: 'own',
        },
      },
      {
        id: 4,
        data: {
          salary: [
            {
              category: 'job',
              type: '',
              yearly: '',
              monthly: '',
              currency: 'IKR',
            },
          ],
          comment: '',
        },
      },
      {
        id: 3,
        data: {
          didRequestChildPension: false,

          childPensionNationalIds: [],
        },
      },
      {
        id: 4,
        data: {
          didApplyForExtraBenefits: false,

          reasonsForExtraBenefits: [],

          benefitsStartDate: '',
        },
      },
    ];

    return {
      type: ApplicationType.Ellilíferyrir,
      currentStage: 0,
      stages: [
        {
          label: ApplicationStageLabel.Nýskráð,
          order: 1,
          status: 'ready',
          prompt: {
            title: 'Halda áfram með umsókn?',
            message:
              'Til þess að klára umsókn þarf að fylla út frekari upplýsingar',
            buttonText: 'Halda áfram',
            buttonRoute: '#',
          },
          form: {
            userConsent: false,
            complete: false,
            currentStep: 0,
            steps: steps,
          },
        },
        {
          label: ApplicationStageLabel.Fylgiskjöl,
          order: 2,
          status: 'ready',
          prompt: {
            title: 'Halda áfram með umsókn?',
            message:
              'Til þess að klára umsókn þarf að fylla út frekari upplýsingar',
            buttonText: 'Halda áfram',
            buttonRoute: '#',
          },
          subText: 'Gögnum skilað',
        },
        {
          label: ApplicationStageLabel.Tekjuáætlun,
          order: 3,
          status: 'ready',
          prompt: {
            title: 'Halda áfram með umsókn?',
            message:
              'Til þess að klára umsókn þarf að fylla út frekari upplýsingar',
            buttonText: 'Halda áfram',
            buttonRoute: '#',
          },
          subText: 'Vantar gögn',
        },
        {
          label: ApplicationStageLabel.ÚrvinnslaGagna,
          order: 4,
          status: 'ready',
          subText: 'Beðið eftir gögnum',
        },
        {
          label: ApplicationStageLabel.Úrskurður,
          order: 5,
          status: 'ready',
        },
        {
          label: ApplicationStageLabel.Niðurstöður,
          order: 6,
          status: 'ready',
        },
      ],
    };
  },
};
