import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, styled } from '@mui/material'
import { useEffect, useState } from 'react'
import { Route, Routes, useMatch, useNavigate } from 'react-router-dom'
import { Breadcrumbs, Crumb } from '../components/Breadcrumbs'
import { Container } from '../components/Containers'
import { PageTitle } from '../components/PageTitle'
import { SideMenu } from '../components/SideMenu'
import { EmptyPage } from './EmptyPage'
import { PrelimCalc } from './PrelimCalc'

const sideMenuItems = [
  { href: '/mytr/yourinfo', label: 'Þínar upplýsingar' },
  { href: '/mytr/files', label: 'Rafræn skjöl' },
  { href: '/mytr/paymentplan', label: 'Greiðsluáætlun' },
  { href: '/mytr/deptsandcontracts', label: 'Skuldir og samningar' }
]

const CRUMBS:Crumb[] = [
  { label: 'Forsíða', href: '/' },
  { label: 'Þú hjá TR', href: '/mytr' },
]

export const MyInfo = () => {
  const match = useMatch('/mytr/*')
  const param = match?.params['*']
  const [crumbs, setCrumbs] = useState<Crumb[]>(CRUMBS)
  const [select, setSelect] = useState('')
  useEffect(() => {
    if(param === 'yourinfo') {
      setCrumbs([...CRUMBS, { label: 'Þínar upplýsingar', active: true } ])
      setSelect('yourinfo')
    }
    else if(param === 'files') {
      setCrumbs([...CRUMBS, { label: 'Rafræn skjöl', active: true } ])
      setSelect('files')
    }
    else if(param === 'paymentplan') {
      setCrumbs([...CRUMBS, { label: 'Greiðslutegund', active: true } ])
      setSelect('paymentplan')
    }
    else if(param === 'deptsandcontracts') {
      setCrumbs([...CRUMBS, { label: 'Skuldir og samningar', active: true } ])
      setSelect('deptsandcontracts')
    }
    else {
      setCrumbs([...CRUMBS])
    }
  }, [param])

  const navigate = useNavigate()
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    navigate(`/mytr/${event.target.value}`)
  }

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <Container>
        <PageTitle heading='Þú hjá TR' />

        <LayoutForm>
          <div className='sideMenu'>
            <SideMenu menu={sideMenuItems} />
          </div>

          <div className='select'>
            <FormControl fullWidth>
              <InputLabel>Upplýsingar</InputLabel>
              <Select
                value={select}
                label="Upplýsingar"
                onChange={handleSelectChange}
              >
                <MenuItem value="yourinfo">Þínar upplýsingar</MenuItem>
                <MenuItem value="files">Rafræn skjöl</MenuItem>
                <MenuItem value="paymentplan">Greiðslutegund</MenuItem>
                <MenuItem value="deptsandcontracts">Skuldir og samningar</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className='content'>
            <Routes>
              <Route path="/yourinfo" element={<EmptyPage header="Þínar upplýsingar"/>} />
              <Route path="/files" element={<EmptyPage header="Rafræn skjöl"/>} />
              <Route path="/paymentplan" element={<PrelimCalc />} />
              <Route path="/deptsandcontracts" element={<EmptyPage header="Skuldir og samningar"/>} />
            </Routes>
          </div>
        </LayoutForm>
      </Container>
    </>
  )
}

const LayoutForm = styled(Box)`
  display: flex;

  .select {
    display: none;
  }
  .content {
    flex-grow: 1;
    margin-left: 24px;
  }
  .sideMenu {
    min-width: 212px;
  }

  ${props => props.theme.breakpoints.down('lg')} {
    flex-wrap: wrap;

    .content {
      flex-grow: 1;
      margin-left: 0;
    }
    .sideMenu {
      width: 100%;
    }
  }
  ${props => props.theme.breakpoints.down('sm')} {
    .sideMenu {
      display: none;
    }
    .select {
      display: block;
      width: 100%;
      margin-bottom: 24px;
    }
  }
`