import React from 'react';

export const SVGBreadcrumbSeparator = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path fill="#FFD372" d="M10 17l5-5-5-5v10z"></path>
  </svg>
)