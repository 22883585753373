import React, { cloneElement } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Paper } from '../components/Paper/Paper'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {
  Grid,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Divider,
} from '@mui/material'
import { TextField as TextFieldApp } from './../components/TextField'
import { FormButton } from './../components/FormButton'
import { FormNavigation } from '../components/FormNavigation'

import styled from 'styled-components'
import { ArrowBack, CheckCircle } from '@mui/icons-material'

const dummyUtreikningar = [
  {
    type: 'Ellilífeyrir',
    month: 'Janúar',
    amount: '18.015',
  },
  {
    type: 'Ellilífeyrir',
    month: 'Febrúar',
    amount: ' 196.891',
  },
  {
    type: 'Ellilífeyrir',
    month: 'Mars',
    amount: ' 196.891',
  },
  {
    type: 'Ellilífeyrir',
    month: 'Apríl',
    amount: ' 196.891',
  },
  {
    type: 'Ellilífeyrir',
    month: 'Maí',
    amount: ' 196.891',
  },
  {
    type: 'Ellilífeyrir',
    month: 'Júní',
    amount: ' 196.891',
  },
  {
    type: 'Ellilífeyrir',
    month: 'Júlí',
    amount: ' 196.891',
  },
  {
    type: 'Ellilífeyrir',
    month: 'Ágúst',
    amount: ' 196.891',
  },
  {
    type: 'Ellilífeyrir',
    month: 'September',
    amount: ' 196.891',
  },
  {
    type: 'Ellilífeyrir',
    month: 'Október',
    amount: ' 196.891',
  },
  {
    type: 'Ellilífeyrir',
    month: 'Nóvember',
    amount: ' 196.891',
  },
  {
    type: 'Ellilífeyrir',
    month: 'Desember',
    amount: ' 196.891',
  },
]
const dummyUtreikningar2 = [
  {
    label: 'Tekjur',
    amount: '260.000',
  },
  {
    label: ' - Almennt frítekjumark',
    amount: '25.000',
  },
  {
    label: ' - Sérstakt frítekjumark v/atv.tekna',
    amount: '200.000',
  },
  {
    label: 'Viðmiðunartekjur',
    amount: '35.000',
  },
  {
    label: 'Skerðingarhlutfall',
    amount: '45%',
  },
  {
    label: 'Frádráttur vegna tekna',
    amount: '15.750',
  },
  {
    label: 'Upphæð án tekjuskerðingar',
    amount: '278.271',
  },
  {
    label: 'Greiðslur miðað við 100% rétt',
    amount: '262.521',
  },
  {
    label: 'Búsetuhlutfall',
    amount: '75,00%',
  },
  {
    label: 'Frestunarhlutfall',
    amount: '100,00%',
  },
]
interface PrelimCalcProps {
  onSubmit?: () => void
  onBack?: () => void
  noBtn?: boolean
}
export const PrelimCalc = ({ onSubmit, onBack, noBtn }: PrelimCalcProps) => {
  return (
    <>
      <Paper 
        header='Bráðabirgðaútreikningar'
        subText='Athugið að útreikningar geti breyst'
      >
        <StyledTypography style={{wordBreak: 'break-all'}} variant='h6' component='h2'>
          Útreikningur 2022 - Janúar til desember
        </StyledTypography>

        <Grid>
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell>Greiðslutegund</TableCell>
                <TableCell>Ellilífeyri</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dummyUtreikningar2.map((data, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{data.label}</TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>
                      {data.amount}
                    </TableCell>
                  </TableRow>
                )
              })}
              <TableRow
                sx={{
                  borderBottom: '1px double #000',
                  borderTop: '1px double #000',
                }}
              >
                <TableCell>Greiðsluréttur</TableCell>
                <TableCell sx={{ textAlign: 'right' }}>196.891</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Greiðsluréttur á ári:</TableCell>
                <TableCell sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                  218.3817
                </TableCell>
              </TableRow>
            </TableBody>
          </StyledTable>
        </Grid>

        <StyledTypography variant='h6' component='h2'>
          Greiðsludreifing yfir eitt ár
        </StyledTypography>
        <Grid >
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell>Tegund bótaflokk</TableCell>
                <TableCell>Mánuðir</TableCell>
                <TableCell>Upphæð</TableCell>
                <TableCell>Gjaldmiðill</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dummyUtreikningar.map((data, index) => {
                return (
                  <TableRow key={index}>
                    <StyldTableCell>{data.type}</StyldTableCell>
                    <StyldTableCell>{data.month}</StyldTableCell>
                    <StyldTableCell sx={{ textAlign: 'right' }}>
                      {data.amount}
                    </StyldTableCell>
                    <StyldTableCell>IKR</StyldTableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </StyledTable>
        </Grid>
      </Paper>
      
      <FormNavigation
        onNext={onSubmit}
        onBack={onBack}
        stepStatus={{ current: 7, total: 9 }}
      />
    </>
  )
}

const StyledTypography = styled(Typography)`
  margin: 1.5rem 0 1.5rem 0;
` as typeof Typography

const StyldTableCell = styled(TableCell)`
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  border-left: 1px solid rgba(224, 224, 224, 1);
  border-right: 1px solid rgba(224, 224, 224, 1);
  border-top: 1px solid rgba(224, 224, 224, 1);
  background-color: #fafafa;
`

const FlexibleTableCell = styled(TableCell)`
  ${({ theme }) => theme.breakpoints.down('sm')}{
    word-break: break-all;
  }
`
const StyledTable = styled(Table)`
  ${({ theme }) => theme.breakpoints.down('sm')}{
   width: unset;
  }
`