import { useLocation, Navigate } from 'react-router-dom';
import { useAppSelector } from '../../app/store';
import { selectUser } from '../../app/users/usersSlice';

// flippa false/true til að sneiða frm hjá authi fyrir þróun
const DISABLE_AUTH = false;

export function RequireAuth({ children }: { children: JSX.Element }) {
  const user = useAppSelector(selectUser);
  const location = useLocation();

  if (user === null && !DISABLE_AUTH) {
    return <Navigate to='/innskraning' state={{ from: location }} replace />;
  }
  return children;
}
