import { ButtonBase as ButtonBaseMui, Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { SVGDocument } from '../svg/SVGDocument';

type FrontPageBoxProps = {
  label: string;
  icon: JSX.Element;
  href: string;
};

export const FrontpageBox = ({ label, icon, href }: FrontPageBoxProps) => (
  <ButtonBase 
    focusRipple 
    component={Link}
    to={href}
    style={{ margin: '0px 0px 25px 0px' }}
  >
    <ContainerBox>
      {icon}
      <TextBox variant='h6' component='h6'>
        {label}
      </TextBox>
    </ContainerBox>
  </ButtonBase>
);

const TextBox = styled(Typography)`
  margin: 0px 25px;
  ${({ theme }) => theme.breakpoints.down('sm')}{
    font-size: 14px;
  }
` as typeof Typography;

const ContainerBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
  /* position: static; */
  /* width: flex; */
  flex: 1;
  width: 100%;

  /* Shadow 1 */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: 0.6s;
  border: 1px solid transparent;
  border-radius: 10px;
  &:hover {
  border: 1px solid transparent;
      border: 1px solid rgba(0,111,81,0.5);
  }
`;

const ButtonBase = styled(ButtonBaseMui)`
  ${({ theme }) => theme.breakpoints.down('sm')}{
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    font-size: 17px;
  }
  flex: 1;
  display: flex;
  background-color: white;
  /* margin: 0px 0px 25px 0px;  */
` as typeof ButtonBaseMui;