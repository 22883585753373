import UploadIcon from '@mui/icons-material/Upload'
import { Box, Grid, styled, Typography } from '@mui/material'
import React from 'react'
import { Alert } from '../components/Alert'
import { Button as MuiButton } from '../components/Button'
import { FormButton } from '../components/FormButton'
import { FormLineEntry } from '../components/FormLineEntry'
import { Paper } from '../components/Paper'
import { TextField } from '../components/TextField'
import { FormNavigation } from '../components/FormNavigation'

interface Props {
  onSubmit?: () => void
  onBack?: () => void

}
export const FileUploadStep = ({ onSubmit, onBack }: Props)  => {

  const [fileNames, setFileNames] = React.useState<any>([]);
  const [fileNamesOther, setFileNamesOther] = React.useState<any>([]);

  const getFilesFromEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileNamesTemp = [];
    if(event && event.target && event.target.files && event.target.files.length > 0) {
      const files = event.target.files;
      for(let i = 0; i < files.length; i++) {
        const file = files[i];
        if(file) {
          const fileName = file.name;
          fileNamesTemp.push([{
            text: fileName,
            label: '',
            index: i,
          }]);
        }
      }
    }
    return fileNamesTemp
  }

  const removeFile = (fileId: number, removeFrom: any, setAfterRemove: Function) => {
    if (fileId > -1 && removeFrom) {
      removeFrom.splice(fileId, 1);
    }
    const newArray = [...removeFrom];
    setAfterRemove(newArray);
  }

  return (
    <form 
      onSubmit={onSubmit}
      noValidate
    >
      <Box>
        <Paper header={'Fylgiskjöl'}>
          <Grid container spacing={4}>

            <GridItem item>
              <Alert severity='info'>
            Fylgiskjöl sem þarf að skila til Tryggingastofnunar eða umboða til að afgreiðsla á umsókn geti hafist eru eftirfarandi:
              </Alert>
            </GridItem>

            <GridItem item>

              <Typography variant='h6' component='h6'>
            Staðfesting frá lífeyrissjóði
              </Typography>

              <TypographyFirst style={{paddingTop: 15}} variant='body1' component='p'>
            Staðfesting á rétti/réttleysi hjá lífeyrissjóðum (þarf ekki ef lífeyrissjóðsgreiðslur eru hafnar eða gert ráð fyrir þeim í tekjuáætlun).
              </TypographyFirst>

              <label htmlFor='icon-button-file'>
                <InputFile id='icon-button-file' type='file' multiple onChange={(event) => {
                  const files = getFilesFromEvent(event);
                  setFileNames(files);
                }} />
                {/* @ts-ignore: Unreachable code error */}
                <UploadButton variant='outlined' color="primary" component='span' size='large'>
                Veldu skrá <UploadIconButton />
                </UploadButton>
              </label>

              {
                fileNames && fileNames.length > 0 ?
                  fileNames.map((files: any, index: number) => {
                    return <FormLineEntry
                      onRemove={(index: number) => {removeFile(index, fileNames, setFileNames.bind(this))}} 
                      items={files}
                      index={index}
                      key={index} />
                  })
                  :
                  <FormLineEntry
                    index={0}
                    noRemove={true} 
                    items={[{text: 'Engin skrá valin', label: ''}]} />
              }

              <Typography style={{paddingTop: 40}} variant='h6' component='h6'>
            Önnur fylgiskjöl
              </Typography>

              <Typography style={{paddingTop: 10, paddingBottom: 15}} variant='body2' component='p'>
            Önnur fylgiskjöl sem þú vilt láta fylgja umsókn
              </Typography>

              <label htmlFor='icon-button-file-other'>
                <InputFile id='icon-button-file-other' type='file' multiple onChange={(event) => {
                  const files = getFilesFromEvent(event);
                  setFileNamesOther(files);
                }} />
                {/* @ts-ignore: Unreachable code error */}
                <UploadButton variant='outlined' color="primary" component='span' size='large'>
                Veldu skrá <UploadIconButton />
                </UploadButton>
              </label>
          
              {
                fileNamesOther && fileNamesOther.length > 0 ?
                  fileNamesOther.map((files: any, index: number) => {
                    return <FormLineEntry
                      onRemove={(index: number) => {removeFile(index, fileNamesOther, setFileNamesOther.bind(this))}} 
                      items={files}
                      index={index}
                      key={index} />
                  })
                  :
                  <FormLineEntry items={[{text: 'Engin skrá valin', label: ''}] } noRemove={true} />
              }

            </GridItem>
        
            <GridItem item >
              <Typography style={{paddingTop: 20, paddingBottom: 23}} variant='h6' component='h6'>
            Athugasemdir
              </Typography>
              <TextField
                fullWidth
                rows={5}
                multiline
                id="athugasemd"
                variant="outlined"
              />      
            </GridItem>
          </Grid>
        </Paper>
      </Box>

      <FormNavigation
        onNext={onSubmit}
        onBack={onBack}
        stepStatus={{ current: 4, total: 9 }}
      />
    </form>
  )
}

const TypographyFirst = styled(Typography)`
  width: 100%;
` as typeof Typography;

const GridItem = styled(Grid)`
  margin-top: 16px;
  width: 100%;
`

const InlineButton = styled(MuiButton)`
  height: 56px;
  width: 116px;
`

const UploadIconButton = styled(UploadIcon)`
  color: ${(props) => {
    return props.theme.palette.primary.main
  }};
`
const UploadButton = styled(MuiButton)`
  margin-top: 23px;
  margin-bottom: 25px;
  border: 1px solid rgba(0,111,81,0.5);
` as typeof MuiButton;

const InputFile = styled('input')`
  display: none;
`