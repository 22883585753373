import { Grid, Typography, Box, styled } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';

interface FormLineEntriesProps {
  items: Array<FormLineEntryProps>,
  noRemove?: boolean,
  onRemove?: Function
  index?: number
}

interface FormLineEntryProps {
  label: string
  text: string
}

export const FormLineEntry = ({items, noRemove, onRemove, index}: FormLineEntriesProps) => {
  return(
    <GridItem item md={12}> 
      <Box style={{display: 'flex', width: '100%', justifyContent: 'flex-end',  alignItems: 'flex-end'}}>
        {
          items.map((value: FormLineEntryProps, index: number) => {
            return (
              <Box key={index} style={{flexDirection: 'column', flex: 1, display: 'flex' }}>
                {
                  value.label ? 
                    <Label>{value.label}</Label>
                    :
                    null
                }
                <ColoredLine>{value.text}</ColoredLine>
              </Box>
            )
          })
        }
        {
          noRemove ? 
            null
            :
            <Box onClick={() => {
              if(onRemove) {
                onRemove(index)
              }
            }} style={{flexDirection: 'column', flex: 0, width: 70, justifyContent: 'flex-start', display: 'flex'}}>
              <ColoredLine> 
                <DeleteIconInline  />
              </ColoredLine>
            </Box>
        }
      </Box>

    </GridItem>
  );
}

const GridItem = styled(Grid)`
  width: 100%;
`

const ColoredLine = styled(Box)`
    background-color: #FAFAFA; 
    /* height: 40px; */
    padding: 12px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    flex: 1;
`;

const DeleteIconInline = styled(DeleteIcon)`
    color: #757575;
    font-size: 1.5rem;
    cursor: pointer;
`;

const Label = styled(Typography)`
  margin-bottom: 9px;
  margin-left: 14px;
` as typeof Typography;